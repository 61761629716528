import React from "react"

import { Box, Tab, Tabs } from "@mui/material"
import { useGetCurrentTabName } from "hooks/navigation"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import { presetsRoutes } from "../Header.config"

const NavigationTabsPresets = () => {
  const { t } = useTranslation()
  const routes = presetsRoutes

  const tabName = useGetCurrentTabName(routes)

  return (
    <Box>
      <Tabs value={tabName} variant="scrollable" scrollButtons={false}>
        {routes.map((route) => (
          <Tab
            label={t(route.labelKey)}
            component={NavLink}
            key={route.labelKey}
            to={route.path}
            value={route.tabName}
          />
        ))}
      </Tabs>
    </Box>
  )
}
export default NavigationTabsPresets
