import { FC } from "react"

import { ReactComponent as IconEdit } from "@common_assets/svg/edit.svg"
import { ReactComponent as IconTrash } from "@common_assets/svg/trash.svg"
import FabIconButton from "components/common/button/fabIconButton"
import FabGroup from "components/common/container/fabGroup"
import { useTranslation } from "react-i18next"
import { TransactionTypeShort } from "ts/types/Transaction"

import { getSetupClassTranslation } from "../../SetupClassTeacher.utils"

interface IProps {
  variant: TransactionTypeShort
  handleOpenEdit: () => void
  handleOpenDelete: () => void
  isDisabled?: boolean
  isDisabledText?: string
}

const ActionButtons: FC<IProps> = ({
  variant,
  handleOpenEdit,
  handleOpenDelete,
  isDisabled,
  isDisabledText,
}) => {
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)

  return (
    <FabGroup>
      <FabIconButton
        sx={{
          ...(isDisabled && {
            "&:hover": {
              backgroundColor: "inherit",
              boxShadow: "none",
            },
          }),
        }}
        iconStyle={
          isDisabled
            ? { opacity: 0.3, pointerEvents: "none" }
            : { opacity: 1, pointerEvents: "auto" }
        }
        onClick={() => {
          if (!isDisabled) {
            handleOpenEdit()
          }
        }}
        Icon={<IconEdit />}
        tooltipText={isDisabled ? isDisabledText : t(tKey.editItem)}
      />
      <FabIconButton
        sx={{
          ...(isDisabled && {
            "&:hover": {
              backgroundColor: "inherit",
              boxShadow: "none",
            },
          }),
        }}
        iconStyle={
          isDisabled
            ? { opacity: 0.3, pointerEvents: "none" }
            : { opacity: 1, pointerEvents: "auto" }
        }
        onClick={() => {
          if (!isDisabled) {
            handleOpenDelete()
          }
        }}
        Icon={<IconTrash />}
        tooltipText={isDisabled ? isDisabledText : t(tKey.deleteItem)}
      />
    </FabGroup>
  )
}

export default ActionButtons
