import React from "react"

import { Stack, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { addYears, subYears } from "date-fns"

import { getErrorMessage } from "./validaiton"

interface DateRangeProps {
  from: Date | undefined
  to: Date | undefined
}

interface DatePickerFieldPros {
  dateRange: DateRangeProps
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeProps>>
}

const DatePickerField = ({ dateRange, setDateRange }: DatePickerFieldPros) => {
  const today = new Date()
  const tenYearsAgo = subYears(today, 10)
  const tenYearsFromNow = addYears(today, 10)

  return (
    <Stack direction="row" spacing={2}>
      <DatePicker
        label="Date from"
        value={dateRange.from ?? null}
        onChange={(newValue) => {
          setDateRange((prev) => ({
            ...prev,
            from: newValue || undefined,
          }))
        }}
        minDate={tenYearsAgo}
        maxDate={dateRange.to ?? tenYearsFromNow}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={params.error}
              helperText={getErrorMessage(
                params,
                "start",
                dateRange.from,
                dateRange.to,
                tenYearsAgo,
                tenYearsFromNow
              )}
            />
          )
        }}
      />
      <DatePicker
        label="Date to"
        value={dateRange.to ?? null}
        onChange={(newValue) => {
          setDateRange((prev) => ({
            ...prev,
            to: newValue || undefined,
          }))
        }}
        minDate={dateRange.from ?? tenYearsAgo}
        maxDate={tenYearsFromNow}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={params.error}
              helperText={getErrorMessage(
                params,
                "end",
                dateRange.from,
                dateRange.to,
                tenYearsAgo,
                tenYearsFromNow
              )}
            />
          )
        }}
      />
    </Stack>
  )
}
export default DatePickerField
