import { TextFieldProps } from "@mui/material"
import { format, isAfter, isBefore, isValid, parse } from "date-fns"

export const getErrorMessage = (
  params: TextFieldProps,
  type: "start" | "end",
  startDate?: Date,
  endDate?: Date,
  minDate?: Date,
  maxDate?: Date
) => {
  if (!params.error) return ""

  const inputValue = params.inputProps?.value
  const parsedDate = parse(inputValue, "MM/dd/yyyy", new Date())

  if (!isValid(parsedDate)) {
    return "Invalid date format. Please use the format MM/DD/YYYY"
  }

  if (
    type === "end" &&
    startDate &&
    isValid(parsedDate) &&
    !isAfter(parsedDate, startDate)
  ) {
    return "The end date cannot be earlier than the start date"
  }

  if (
    type === "start" &&
    endDate &&
    isValid(parsedDate) &&
    isAfter(parsedDate, endDate)
  ) {
    return "The start date cannot be later than the end date"
  }

  if (minDate && isBefore(parsedDate, minDate)) {
    return `Date cannot be earlier than ${format(minDate, "MM/dd/yyyy")}`
  }

  if (maxDate && isAfter(parsedDate, maxDate)) {
    return `Date cannot be later than ${format(maxDate, "MM/dd/yyyy")}`
  }

  return "Invalid date format"
}
