import { FC } from "react"

import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import ErrorText from "components/common/error/errorText"
import DynamicIcon from "components/common/icon/dynamicIcon"
import IconWrapper from "components/common/icon/iconWrapper"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useClassId, useIsMatchingClassRoutes } from "hooks/navigation"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { matchPath, useLocation } from "react-router"
import { selectIsMenuOpened } from "store/menu/menu.selectors"
import { IconName, IconType } from "ts/types/Icon"

import { basicRoutes } from "../Header.config"
import NavigationTabs from "../navigationTabs"
import NavigationTabsPresets from "../navigationTabsPresets"
import Burger from "./burger"

interface IProps {
  routeName?: string
  iconName?: IconType
  isLoading?: boolean
  isError?: boolean
}

const SubNavigation: FC<IProps> = ({
  routeName,
  iconName,
  isLoading = false,
  isError = false,
}) => {
  const { t } = useTranslation()
  const isDesktop = useIsBreakpointUp("desktop")
  const isTablet = useIsBreakpointUp("tablet")
  const isToggle = useAppSelector(selectIsMenuOpened)
  const { pathname } = useLocation()

  const classId = useClassId()

  const isMatchingClassRoutes = useIsMatchingClassRoutes()

  const getRouteName = () => {
    if (classId) return routeName
    const route = basicRoutes.find((route) => matchPath(route, pathname))

    return route?.name ? t(route.name) : t("layout.pageNotFound")
  }

  const getRouteIcon = (): IconType => {
    if (classId) return iconName || "star"
    const route = basicRoutes.find((route) => matchPath(route, pathname))

    return (route?.icon as IconName) || "home"
  }

  const actualState = localStorage.getItem("isMenuOpened")
  const asidebarOpened: boolean = actualState && JSON.parse(actualState)

  return (
    <Stack direction="row" alignItems="center">
      {(isDesktop
        ? !isDesktop
          ? isToggle
          : !asidebarOpened
        : !isDesktop || isToggle) && <Burger />}
      <IconWrapper
        color="primary.main"
        bgcolor="mockup.primary90"
        p="8px"
        borderRadius="8px"
        height="40px"
        width="40px"
        mr={isTablet ? "20px" : "8px"}
      >
        {isLoading && <CircularProgress size="24px" />}
        {!isLoading && <DynamicIcon name={getRouteIcon()} variant="outlined" />}
      </IconWrapper>
      <Stack
        height="100%"
        justifyContent={
          (isMatchingClassRoutes && isTablet) || pathname.includes("presets")
            ? "start"
            : "center"
        }
      >
        <Typography
          color="mockup.darkBlue"
          variant={isTablet ? "subtitle1" : "subtitle2"}
        >
          {isLoading && <CircularProgress size="24px" />}
          {getRouteName()}
        </Typography>
        {isError && <ErrorText />}
        {isMatchingClassRoutes && isTablet && (
          <Box sx={{ position: "absolute", bottom: "0" }}>
            <NavigationTabs />
          </Box>
        )}
        {pathname.includes("presets") && isTablet && (
          <Box sx={{ position: "absolute", bottom: "0" }}>
            <NavigationTabsPresets />
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export default SubNavigation
