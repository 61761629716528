import { FC, useEffect, useMemo, useState } from "react"

import { Checkbox, Typography } from "@mui/material"
import { useMutationPostClassesTransactionItems } from "api/reactQuery/mutations/classesTransactionItems"
import { useQueryClassesById } from "api/reactQuery/queries/classes"
import Dialog from "components/common/dialog/dialog"
import HelpLink from "components/common/link/helpLink"
import BasicTable from "components/common/table/basicTable"
import { useDialog } from "hooks/dialog"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useLocation } from "react-router"
import { TransactionTypeShort } from "ts/types/Transaction"
import { getErrorMessageFromTab } from "utils/api"
import { showDecimal } from "utils/decimalNumber"

import {
  useMutationPostTeacherAdminBonuses,
  useMutationPostTeacherAdminFines,
} from "../../../../../api/reactQuery/mutations/adminTeacher"
import { getSetupClassTranslation } from "../../SetupClassTeacher.utils"
import BodyTableSuggested from "./bodyTableSuggested/BodyTableSuggested"
import {
  OptionType,
  UpdateAmountType,
  UpdateItemType,
} from "./DialogAddSuggested.types"
import { getOptions } from "./DialogAddSuggested.utils"

interface IProps {
  variant: TransactionTypeShort
  isOpened: boolean
  onClose: () => void
}

const DialogAddSuggested: FC<IProps> = ({ variant, isOpened, onClose }) => {
  const classId = useClassId()
  const location = useLocation()

  const isAdmin = location.pathname.includes("admin") && !classId

  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)

  const options = useMemo(() => getOptions(variant), [variant])
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([])
  const [filterOptions, setFilterOptions] = useState<OptionType[]>([])

  const { isOpen, handleOpen, handleClose } = useDialog()

  const getIsChecked = (el: OptionType) => {
    return !!selectedOptions.find((item) => item.id === el.id)
  }

  const toggleAll = () => {
    if (selectedOptions.length === filterOptions.length) {
      setSelectedOptions([])
    } else {
      setSelectedOptions([...filterOptions])
    }
  }

  const toggleItem = (el: OptionType) => {
    const isChecked = getIsChecked(el)
    if (isChecked) {
      setSelectedOptions((prev) => prev.filter((item) => item.id !== el.id))
    } else {
      setSelectedOptions([...selectedOptions, el])
    }
  }

  const updateToggleItem = (el: UpdateItemType) => {
    setSelectedOptions((prev) =>
      prev.map((element) => {
        if (element.id === el.id) {
          return { ...element, title: el.title }
        }
        return element
      })
    )
  }

  const updateToggleAmount = (el: UpdateAmountType) => {
    const checkNumber = showDecimal(`${el.amount}`)
    setSelectedOptions((prev) =>
      prev.map((element) => {
        if (element.id === el.id) {
          return {
            ...element,
            amount: isNaN(Number(checkNumber)) ? 0 : Number(checkNumber),
          }
        }
        return element
      })
    )
  }

  const { isLoading, mutate: postTransactionItems } =
    useMutationPostClassesTransactionItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(`classes${variant}`)
          showSnackbar({
            title: t(tKey.suggestedItemsHaveBeenAdded),
          })
          setSelectedOptions([])
          onClose()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessageFromTab(error)),
            variant: "error",
          })
        },
      },
    })

  const { isLoading: isLoadingPostAdminBonuses, mutate: postAdminBonuses } =
    useMutationPostTeacherAdminBonuses({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(`admin${variant}`)
          showSnackbar({
            title: t(tKey.suggestedItemsHaveBeenAdded),
          })
          setSelectedOptions([])
          onClose()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessageFromTab(error)),
            variant: "error",
          })
        },
      },
    })

  const { isLoading: isLoadingPostAdminFines, mutate: postAdminFines } =
    useMutationPostTeacherAdminFines({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(`admin${variant}`)
          showSnackbar({
            title: t(tKey.suggestedItemsHaveBeenAdded),
          })
          setSelectedOptions([])
          onClose()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessageFromTab(error)),
            variant: "error",
          })
        },
      },
    })

  const { data } = useQueryClassesById({
    id: Number(classId),
    options: {
      enabled: !!classId,
    },
  })

  useEffect(() => {
    let filterType: "ms" | "es" | "ps" | undefined = undefined

    if (isAdmin) {
      if (variant === "bonus" || variant === "fine") {
        const filters = options.filter((item) => item.type === "ms")
        setFilterOptions(filters)
      }
      return
    }

    if (
      (variant === "bonus" || variant === "fine") &&
      (data?.data.grade === "K" || Number(data?.data.grade) <= 2)
    ) {
      filterType = "es"
    }

    if (
      (variant === "bonus" || variant === "fine") &&
      Number(data?.data.grade) > 2
    ) {
      filterType = "ms"
    }

    if (
      variant === "expense" &&
      (data?.data.grade === "K" || Number(data?.data.grade) <= 2)
    ) {
      filterType = "es"
    }

    if (variant === "expense" && Number(data?.data.grade) <= 5) {
      filterType = "ms"
    }

    if (variant === "expense" && Number(data?.data.grade) > 5) {
      filterType = "ps"
    }

    if (filterType) {
      const filters = options.filter((el) => el.type === filterType)
      setFilterOptions(filters)
    }
  }, [options, isOpened, data?.data.grade])

  return (
    <>
      <Dialog
        open={isOpened}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            handleOpen()
          }
        }}
        titleText={t(tKey.addSuggestedItems)}
        desktopMaxWidth="867px"
        tabletMaxWidth="867px"
        isLoading={
          isLoading || isLoadingPostAdminBonuses || isLoadingPostAdminFines
        }
        onActionButtonClick={() => {
          if (selectedOptions.find(({ title }) => !title.length)) {
            showSnackbar({
              title: t("setup.selectedItemsCannotHaveAnEmptyTitle"),
              variant: "error",
            })
            return
          } else if (selectedOptions.find(({ amount }) => amount < 0)) {
            showSnackbar({
              title: t("setup.amountOfSelectedItemsHaveToBePositiveNumber"),
              variant: "error",
            })
            return
          }

          if (isAdmin) {
            if (variant === "bonus") {
              postAdminBonuses(
                [...selectedOptions].map((item) => ({
                  title: t(item.title),
                  amount: item.amount,
                }))
              )
              return
            }

            if (variant === "fine") {
              postAdminFines(
                [...selectedOptions].map((item) => ({
                  title: t(item.title),
                  amount: item.amount,
                }))
              )
              return
            }
          }

          postTransactionItems({
            classId: Number(classId),
            variant,
            data: [...selectedOptions].map(({ title, amount }) => ({
              title: t(title),
              amount,
            })),
          })
        }}
        actionAcceptButtonProps={{ disabled: !selectedOptions.length }}
      >
        <Typography variant="body2" color="mockup.neutral10">
          {t("setup.clickOnTheTextToEditTheSuggestedSettings")}
        </Typography>
        <BasicTable
          head={
            <BasicTable.Row>
              <BasicTable.Cell width="50px" px="8px">
                <Checkbox
                  checked={filterOptions.length === selectedOptions.length}
                  onChange={toggleAll}
                  indeterminate={
                    filterOptions.length !== selectedOptions.length &&
                    !!selectedOptions.length
                  }
                />
              </BasicTable.Cell>
              <BasicTable.Cell
                minWidth="100px"
                tableCellProps={{ width: "100%" }}
              >
                <BasicTable.HeadText>{t(tKey.itemTitle)}</BasicTable.HeadText>
              </BasicTable.Cell>
              <BasicTable.Cell width="80px">
                <BasicTable.HeadText>{t("setup.amount")}</BasicTable.HeadText>
              </BasicTable.Cell>
            </BasicTable.Row>
          }
          body={filterOptions.map(({ title, amount, id }) => (
            <BodyTableSuggested
              key={id}
              id={id ?? 0}
              title={title}
              amount={amount}
              getIsChecked={getIsChecked}
              toggleItem={toggleItem}
              updateToggleItem={updateToggleItem}
              updateToggleAmount={updateToggleAmount}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              handleOpen={handleOpen}
            />
          ))}
        />
        {variant === "bonus" && (
          <Typography variant="body2">
            &#42; {t("setup.weRecommendSelectingBonuses")}
          </Typography>
        )}
        {variant === "fine" && (
          <Typography variant="body2">
            &#42; {t("setup.weRecommendSelectingFines")}
          </Typography>
        )}
        {process.env.REACT_APP_TYPE === "classequity" && <HelpLink />}
      </Dialog>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        titleText={t("setup.areYouSureYouWantToExit")}
        actionAcceptText={t("setup.accept")}
        onActionButtonClick={() => {
          setSelectedOptions([])
          onClose()
          handleClose()
          mixpanel.track("add_class_settings", {
            action: `cancel_suggested_${variant}`,
          })
        }}
      >
        <Typography>{t("setup.noChangesWillBeSaved")}</Typography>
      </Dialog>
    </>
  )
}

export default DialogAddSuggested
