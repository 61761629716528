import { AxiosError, AxiosResponse } from "axios"
import qs from "qs"
import { useInfiniteQuery, UseInfiniteQueryResult, useQuery } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import { presetItemsEndpoint } from "./teacherAdmin.config"
import {
  BonusesAdminArgs,
  BonusesAdminTypes,
  ExportAdminTransactionsArgs,
  TeacherAdminSchoolTransactionsInfinityArgs,
  TeacherAdminSchoolTransactionsResponse,
  TeacherAdminSchoolTransactionsSchoolDataArgs,
  TeacherAdminSchoolTransactionsSchoolDataResponse,
  TeachersManagement,
  TeachersManagementArgs,
} from "./teacherAdmin.types"

export const useInfiniteTeacherAdminSchoolTransactions = (
  args: TeacherAdminSchoolTransactionsInfinityArgs
): UseInfiniteQueryResult<
  AxiosResponse<TeacherAdminSchoolTransactionsResponse[]>,
  AxiosError
> => {
  return useInfiniteQuery<
    AxiosResponse<TeacherAdminSchoolTransactionsResponse[]>,
    AxiosError
  >(
    [
      "teacherAdminSchoolTransactionsInfinite",
      args.students,
      args.date_from,
      args.date_to,
    ],
    async ({ pageParam = 0 }) =>
      axiosInstance(Endpoint.TEACHER_ADMIN_SCHOOL_TRANSACTIONS, {
        params: {
          students: args.students,
          date_from: args.date_from,
          date_to: args.date_to,
          classes: args.classes,
          student_helpers: args.student_helpers,
          teachers: args.teachers,
          type_in: args.type_in,
          title: args.title,
          sender_type: args.sender_type,
          skip: pageParam,
          limit: args.limit,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false })
        },
      }),
    {
      ...args.options,
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.data.length) return undefined

        const nextSkip = allPages.reduce(
          (acc, page) => acc + page.data.length,
          0
        )
        return nextSkip
      },
    }
  )
}

export const useQuerySchoolTransactionsSchoolData = (
  args: TeacherAdminSchoolTransactionsSchoolDataArgs
) =>
  useQuery<
    AxiosResponse<TeacherAdminSchoolTransactionsSchoolDataResponse>,
    AxiosError
  >(
    ["schoolData"],
    async () =>
      axiosInstance(Endpoint.TEACHER_ADMIN_SCHOOL_TRANSACTIONS_SCHOOL_DATA),
    { ...args.options }
  )

export const useQueryTeachersManagement = (
  args?: TeachersManagementArgs
): UseInfiniteQueryResult<AxiosResponse<TeachersManagement[]>, AxiosError> =>
  useInfiniteQuery<AxiosResponse<TeachersManagement[]>, AxiosError>(
    ["teachersManagement", args?.filter],
    async ({ pageParam = 0 }) =>
      axiosInstance(Endpoint.TEACHER_ADMIN_TEACHERS_MANAGEMENT, {
        params: {
          skip: pageParam,
          limit: args?.limit,
          filter: args?.filter,
          sort_by: args?.sort_by,
          sorting: args?.sorting,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false })
        },
      }),
    {
      ...args?.options,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length < 20) return undefined

        const nextSkip = allPages.reduce(
          (acc, page) => acc + page.data.length,
          0
        )
        return nextSkip
      },
    }
  )

export const useQueryTeacherManagementPending = (
  args?: TeachersManagementArgs
): UseInfiniteQueryResult<AxiosResponse<TeachersManagement[]>, AxiosError> =>
  useInfiniteQuery<AxiosResponse<TeachersManagement[]>, AxiosError>(
    ["teachersManagementPending", args?.filter],
    async ({ pageParam = 0 }) =>
      axiosInstance(
        Endpoint.TEACHER_ADMIN_TEACHERS_MANAGEMENT_PENDING_TEACHERS,
        {
          params: {
            skip: pageParam,
            limit: args?.limit,
            filter: args?.filter,
            sort_by: args?.sort_by,
            sorting: args?.sorting,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false })
          },
        }
      ),
    {
      ...args?.options,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length < 20) return undefined

        const nextSkip = allPages.reduce(
          (acc, page) => acc + page.data.length,
          0
        )
        return nextSkip
      },
    }
  )

export const useQueryItemsAdmin = (args: BonusesAdminArgs) =>
  useQuery<AxiosResponse<BonusesAdminTypes[]>, AxiosError>(
    [`admin${args.variant}`],
    async () => axiosInstance(presetItemsEndpoint[args.variant]),
    { ...args.options }
  )

export const useQueryExportAdminTransactions = (
  args: ExportAdminTransactionsArgs
) =>
  useQuery<AxiosResponse<Blob>, AxiosError>(
    ["exportAdminTransactions"],
    async () =>
      axiosInstance(Endpoint.TEACHER_ADMIN_SCHOOL_TRANSACTIONS_EXPORT, {
        params: {
          students: args.students,
          date_from: args.date_from,
          date_to: args.date_to,
          classes: args.classes,
          student_helpers: args.student_helpers,
          teachers: args.teachers,
          type_in: args.type_in,
          title: args.title,
          sender_type: args.sender_type,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false })
        },
        responseType: "blob",
      }),
    args.options
  )
