import React, { useEffect, useState } from "react"

import { MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import {
  selectIsClickedReset,
  selectSender,
} from "store/adminTransaction/adminTransactions.selectors"
import { setSender } from "store/adminTransaction/adminTransactions.slice"
import { SenderType } from "ts/types/SenderType"

const Sender = () => {
  const { t } = useTranslation()
  const initialState = useAppSelector(selectSender)
  const [sender, senderType] = useState<SenderType>(initialState)
  const dispatch = useAppDispatch()
  const { teachers, helpers } = useAppSelector((state) => ({
    teachers: state.adminTransactions.teachersId,
    helpers: state.adminTransactions.helpersId,
  }))
  const isShouldReset = useAppSelector(selectIsClickedReset)

  useEffect(() => {
    dispatch(setSender(sender))
  }, [sender])

  useEffect(() => {
    if (isShouldReset) {
      setSender("")
    }
  }, [isShouldReset])

  return (
    <Stack rowGap={1}>
      <Typography>{t("adminDashboard.sender")}</Typography>
      <Tooltip
        followCursor
        title={
          !!teachers.length || !!helpers.length
            ? "Remove the teacher or student helper to be able to select the sender."
            : ""
        }
      >
        <TextField
          select
          onChange={(e) => senderType(e.target.value as SenderType)}
          value={sender}
          label="Select Sender"
          disabled={!!teachers.length || !!helpers.length}
        >
          <MenuItem value="" disableRipple>
            <Typography fontWeight="500">None</Typography>
          </MenuItem>
          <MenuItem value="teacher" disableRipple>
            <Typography fontWeight="500">Teacher</Typography>
          </MenuItem>
          <MenuItem value="helper" disableRipple>
            <Typography fontWeight="500">Helper</Typography>
          </MenuItem>
        </TextField>
      </Tooltip>
    </Stack>
  )
}
export default Sender
