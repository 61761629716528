import React, {
  Dispatch,
  FC,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from "react"

import { ReactComponent as IconExport } from "@common_assets/svg/export.svg"
import { ReactComponent as IconSettings } from "@common_assets/svg/settings.svg"
import {
  Button,
  CircularProgress,
  Fab,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { useMutationEditClass } from "api/reactQuery/mutations/classes"
import { useQueryClassesById } from "api/reactQuery/queries/classes"
import DialogUpgradeAccount from "components/common/dialogUpgradeAccount/DialogUpgradeAccount"
import SearchbarForm from "components/form/searchbarForm"
import { IProps as ISearchbarFormProps } from "components/form/searchbarForm/SearchbarForm.types"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"
import { selectUser } from "store/auth/auth.selectors"
import { selectDisplaySavings } from "store/displayMode/displayMode.selectors"
import { setDisplaySavings } from "store/displayMode/displayMode.slice"
import { ExportIconWrapper } from "styles/common/box"
import { PopOverStyle } from "styles/common/poprow"
import { getErrorMessageFromTab } from "utils/api"
import { schoolPlanToExportData, hiddenExport } from "utils/export"

import SettingsContent from "../../module/setupClassTeacher/common/settingsContent/SettingsContent"

interface IProps {
  searchbarFormProps: ISearchbarFormProps
  buttonTitle: string
  onButtonClick: (
    e?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void
  actionSlot?: ReactNode
  isExport?: boolean
  isFetching?: boolean
  setIsFetching?: Dispatch<React.SetStateAction<boolean>>
  refetch?: () => void
  isAddNewClass?: boolean
  savingsAccountOption?: boolean
}

export interface AutoExpenseType {
  isActive: boolean
  value: string
}

const SearchbarFormWithButtons: FC<IProps> = ({
  searchbarFormProps,
  buttonTitle,
  onButtonClick,
  actionSlot,
  isExport,
  isFetching,
  setIsFetching,
  refetch,
  isAddNewClass,
  savingsAccountOption,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const userInfo = useAppSelector(selectUser)
  const stateSavings = useAppSelector(selectDisplaySavings)
  const dispatch = useDispatch()
  const params = useParams()
  const isTablet = useIsBreakpointUp("tablet")
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()

  const [autoExpense, setAutoExpense] = useState<AutoExpenseType>({
    isActive: false,
    value: "",
  })

  useEffect(() => {
    dispatch(setDisplaySavings(stateSavings))
    localStorage.setItem("switchState", JSON.stringify(stateSavings))
  }, [stateSavings])

  const {
    isOpen: isDialogOpenUpgradeAccount,
    handleOpen: handleOpenDialogUpgradeAccount,
    handleClose: handleCloseDialogUpgradeAccount,
  } = useDialog()

  const { payIt, isLoadingPayments } = useCustomPayment()

  const { handleClick, handleClose, popoverAnchorEl } =
    usePopover<HTMLButtonElement>()

  const { data: classes } = useQueryClassesById({
    id: Number(params.classId),
    options: {
      enabled: buttonTitle === "Add bill",
    },
  })

  useEffect(() => {
    if (classes) {
      setAutoExpense({
        isActive: classes?.data.expense_automatic,
        value: classes?.data.expense_interval,
      })
    }
  }, [classes])

  const { mutate, isLoading } = useMutationEditClass({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: "Autoexpense has been changed successfully",
          variant: "success",
        })
        queryClient.invalidateQueries(["classes", Number(params.classId)])
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const handleSendAutoexpense = () => {
    handleClose()
    if (
      autoExpense.isActive === classes?.data.expense_automatic &&
      autoExpense.value === classes?.data.expense_interval
    )
      return

    mutate({
      classId: Number(params.classId),
      data: {
        expense_automatic: autoExpense.isActive,
        expense_interval: autoExpense.value,
      },
    })
  }

  return (
    <Stack
      direction={{ mobile: "column", tablet: "row" }}
      alignItems="center"
      p="16px"
      rowGap="24px"
      columnGap="16px"
      width="100%"
    >
      <SearchbarForm {...searchbarFormProps} />
      {!isAddNewClass && (
        <Stack sx={!isTablet ? { width: "100%" } : {}}>
          {savingsAccountOption && !isTablet && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              pb={3}
            >
              <Typography
                variant="subtitle1"
                letterSpacing="0.16px"
                lineHeight="16px"
                fontSize="16px"
              >
                {stateSavings
                  ? t("students.hideCheckingAndSavingsTotals")
                  : t("students.showCheckingAndSavingsTotals")}
              </Typography>
              <Switch
                checked={stateSavings}
                onChange={(e) => dispatch(setDisplaySavings(e.target.checked))}
              />
            </Stack>
          )}
          <Stack
            direction="row"
            width={{ mobile: "100%", tablet: "max-content" }}
            gap="16px"
          >
            {savingsAccountOption && isTablet && (
              <Stack direction="row-reverse" alignItems="center" gap="8px">
                <Typography
                  variant="subtitle1"
                  letterSpacing="0.16px"
                  lineHeight="12px"
                  fontSize="12px"
                >
                  {stateSavings
                    ? t("students.hideCheckingAndSavingsTotals")
                    : t("students.showCheckingAndSavingsTotals")}
                </Typography>
                <Switch
                  checked={stateSavings}
                  onChange={(e) =>
                    dispatch(setDisplaySavings(e.target.checked))
                  }
                />
              </Stack>
            )}
            <Stack direction="row" justifyContent="end" width="100%" gap="12px">
              {actionSlot}
              <Button
                onClick={onButtonClick}
                sx={{
                  width: { mobile: "100%", tablet: "150px" },
                  paddingX: { mobile: "12px", tablet: "8px" },
                }}
              >
                {buttonTitle}
              </Button>
              {buttonTitle === "Add bill" && !!Object.keys(params).length && (
                <>
                  <Fab onClick={handleClick} disabled={isLoading}>
                    <IconSettings />
                  </Fab>
                  <PopOverStyle
                    open={!!popoverAnchorEl}
                    onClose={handleSendAutoexpense}
                    anchorEl={popoverAnchorEl}
                    anchorOrigin={{
                      vertical: 60,
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <SettingsContent
                      onSubmitAutoexpense={handleSendAutoexpense}
                      isDisabled={
                        autoExpense.isActive ===
                          classes?.data.expense_automatic &&
                        autoExpense.value === classes?.data.expense_interval
                      }
                      onChangeIsActive={(e) => {
                        setAutoExpense((prev) => ({
                          ...prev,
                          isActive: e.target.checked,
                        }))
                        mixpanel.track("autopay_state", {
                          action: "autoExpense_checkbox",
                        })
                      }}
                      onChange={(e) => {
                        setAutoExpense((prev) => ({
                          ...prev,
                          value: e.target.value,
                        }))
                        mixpanel.track("autopay_state", {
                          action: "expense_interval",
                          interval: autoExpense.value,
                        })
                      }}
                      autoExpense={autoExpense}
                    />
                  </PopOverStyle>
                </>
              )}
              {isExport && hiddenExport(userInfo) && (
                <Tooltip title={t("students.exportData")} placement="top">
                  <ExportIconWrapper
                    onClick={() => {
                      if (refetch && setIsFetching) {
                        schoolPlanToExportData({
                          user: userInfo,
                          upgrade: handleOpenDialogUpgradeAccount,
                          exportData: refetch,
                        })
                        setIsFetching(false)
                      }
                    }}
                  >
                    <span style={{ margin: "auto" }}>
                      {isFetching ? (
                        <CircularProgress />
                      ) : (
                        <IconExport
                          style={{
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                    </span>
                  </ExportIconWrapper>
                </Tooltip>
              )}
            </Stack>
            <DialogUpgradeAccount
              open={isDialogOpenUpgradeAccount}
              onClose={() => {
                handleCloseDialogUpgradeAccount()
                mixpanel.track("reports_export", {
                  action: "cancel",
                })
              }}
              onActionButtonClick={() => {
                payIt(null)
                mixpanel.track("reports_export", {
                  methods: "class_transactions",
                  action: "submit",
                })
              }}
              isLoading={isLoadingPayments}
              isExport
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default SearchbarFormWithButtons
