import { FC } from "react"

import {
  useMutationPostTeacherAdminBonuses,
  useMutationPostTeacherAdminFines,
} from "api/reactQuery/mutations/adminTeacher"
import { useMutationPostClassesTransactionItems } from "api/reactQuery/mutations/classesTransactionItems"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { get } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useLocation } from "react-router"
import { TransactionTypeShort } from "ts/types/Transaction"
import { getErrorMessage, getErrorMessageFromTab } from "utils/api"

import { getSetupClassTranslation } from "../../SetupClassTeacher.utils"
import TransactionSetupDialogForm from "../transactionSetupDialogForm"

interface IProps {
  variant: TransactionTypeShort
  isOpened: boolean
  onClose: () => void
}

const DialogAddItem: FC<IProps> = ({ variant, isOpened, onClose }) => {
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)
  const classId = useClassId()
  const location = useLocation()
  const isAdmin = location.pathname.includes("admin")

  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  const { isLoading: isLoadingPost, mutateAsync: postTransactionItem } =
    useMutationPostClassesTransactionItems({
      options: {
        onSuccess: (data) => {
          queryClient.invalidateQueries(`classes${variant}`)
          showSnackbar({
            title: t(tKey.itemHasBeenAdded, { name: data.data[0].title }),
          })
        },
        onError: (error) => {
          const isUniqueError =
            get(error.response?.data, "detail.0.type", null) ===
            "value_error.unique"

          if (isUniqueError) {
            showSnackbar({
              title: t(tKey.titleShouldBeUnique),
              variant: "error",
            })
            return
          }
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  const { mutateAsync: addBonuses } = useMutationPostTeacherAdminBonuses({
    options: {
      onError: (err) => {
        showSnackbar({
          variant: "error",
          title: getErrorMessageFromTab(err),
        })
      },

      onSuccess: () => {
        queryClient.invalidateQueries([`admin${variant}`])
        showSnackbar({
          title: "Preset has been added successfully!",
        })
      },
    },
  })

  const { mutateAsync: addFines } = useMutationPostTeacherAdminFines({
    options: {
      onError: (err) => {
        showSnackbar({
          variant: "error",
          title: getErrorMessageFromTab(err),
        })
      },

      onSuccess: () => {
        queryClient.invalidateQueries([`admin${variant}`])
        showSnackbar({
          title: "Preset has been added successfully!",
        })
      },
    },
  })

  return (
    <TransactionSetupDialogForm
      variant={variant}
      open={isOpened}
      onClose={onClose}
      title={t(tKey.addNewItem)}
      titleFieldLabel={t(tKey.itemTitle)}
      isLoading={isLoadingPost}
      onSubmit={(data) => {
        if (isAdmin) {
          if (variant === "bonus") {
            return addBonuses([
              {
                title: data.title,
                amount: Number(data.amount),
              },
            ])
          } else {
            return addFines([
              {
                title: data.title,
                amount: Number(data.amount),
              },
            ])
          }
        }
        return postTransactionItem({
          classId: Number(classId),
          variant,
          data: [
            {
              title: data.title,
              amount: Number(data.amount),
            },
          ],
        })
      }}
    />
  )
}

export default DialogAddItem
