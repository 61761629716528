import React, { useEffect, useState } from "react"

import {
  Autocomplete,
  Box,
  Checkbox,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { TeacherAdminSchoolTransactionsSchoolDataResponse } from "api/reactQuery/queries/teacherAdmin.types"
import { ReactComponent as IconSearch } from "assets/common/svg/search.svg"
import { AxiosResponse } from "axios"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import {
  setHelpersId,
  setTeachersId,
} from "store/adminTransaction/adminTransactions.slice"

import {
  selectIsClickedReset,
  selectSender,
} from "../../../../../../store/adminTransaction/adminTransactions.selectors"

type TeachersAndHelpers =
  | TeacherAdminSchoolTransactionsSchoolDataResponse["teachers"][number]
  | TeacherAdminSchoolTransactionsSchoolDataResponse["helpers"][number]

const isTeacherAdmin = (
  user: TeachersAndHelpers
): user is TeacherAdminSchoolTransactionsSchoolDataResponse["teachers"][number] => {
  return "email" in user
}

const TeacherSearch = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { teacher, helpers } = useAppSelector((state) => ({
    teacher: state.adminTransactions.teachersId,
    helpers: state.adminTransactions.helpersId,
  }))
  const isSender = useAppSelector(selectSender)
  const queryClient = useQueryClient()
  const isShouldReset = useAppSelector(selectIsClickedReset)

  const data = queryClient.getQueryData<
    AxiosResponse<TeacherAdminSchoolTransactionsSchoolDataResponse>
  >(["schoolData"])

  const fullLists = (initial: number[]) => {
    if (data) {
      const common = [...data?.data.teachers, ...data?.data.helpers]
      return common.filter((group) => initial.includes(group.id))
    }
    return []
  }

  const [isSelected, setIsSelected] = useState<TeachersAndHelpers[]>(
    fullLists([...teacher, ...helpers])
  )
  const [isFocus, setIsFocus] = useState(false)
  const [fullData, setFullData] = useState<TeachersAndHelpers[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isShouldReset) {
      setIsSelected([])
    }
  }, [isShouldReset])

  useEffect(() => {
    if (data) {
      const sortData: TeachersAndHelpers[] = [
        ...(data?.data.teachers || []),
        ...(data?.data.helpers || []),
      ].sort((a, b) => a.first_name.localeCompare(b.first_name))
      setFullData(sortData)
    }
  }, [data])

  const handleOptionClick = (
    event: React.MouseEvent | React.ChangeEvent,
    option: TeachersAndHelpers
  ): void => {
    event.preventDefault()
    setIsSelected((prev) => {
      const isSelected = prev.some((item) => item.id === option.id)
      if (isSelected) {
        return prev.filter((item) => item.id !== option.id)
      } else {
        return [...prev, option]
      }
    })
  }

  const handleClickOut = (id: number) => {
    setIsSelected((prev) => prev.filter((item) => item.id !== id))
  }

  useEffect(() => {
    const teachersID = isSelected.filter(isTeacherAdmin).map((item) => item.id)
    const helperID = isSelected
      .filter((item) => !isTeacherAdmin(item))
      .map((item) => item.id)

    dispatch(setTeachersId(teachersID))
    dispatch(setHelpersId(helperID))
  }, [isSelected])

  return (
    <Tooltip
      title={
        !!isSender.length
          ? "You need to clear the sender to be able to filter by teachers or student helpers."
          : ""
      }
      followCursor
    >
      <Stack rowGap={2}>
        <Typography>
          {t("adminDashboard.teacher")} / {t("adminDashboard.studentHelper")}
        </Typography>

        <Autocomplete<TeachersAndHelpers, false>
          disableCloseOnSelect
          disabled={!!isSender.length}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name}`
          }
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                variant="outlined"
                label={t("adminDashboard.searchForTeachersAndStudentHelpers")}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch
                        style={{ color: theme.palette.mockup.primary60 }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    transform: "translate(40px, 16px) scale(1)",
                    "&.Mui-focused, &.MuiFormLabel-filled": {
                      transform: "translate(14px, -9px) scale(0.75)",
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: isFocus,
                }}
              />
            )
          }}
          options={fullData ?? []}
          renderOption={(_, option) => (
            <li
              key={option.id}
              onClick={(event) => {
                event.preventDefault()
                handleOptionClick(event, option)
              }}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                cursor: "pointer",
              }}
            >
              <Checkbox
                checked={isSelected.some((el) => el.id === option.id)}
                onChange={(event) => {
                  event.preventDefault()
                  handleOptionClick(event, option)
                }}
              />
              <Stack
                direction="row"
                columnGap={1}
                width="100%"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Typography fontWeight="500" color="mockup.neutral10">
                    {option.first_name} {option.last_name}
                  </Typography>
                  <Typography fontWeight="500" color="mockup.neutral40">
                    {isTeacherAdmin(option)
                      ? `(${option.email})`
                      : `(${option.login})`}
                  </Typography>
                </Stack>
                {!isTeacherAdmin(option) && (
                  <Typography
                    variant="body3"
                    sx={{
                      backgroundColor: "mockup.primary40",
                      borderRadius: 2,
                      p: 0.5,
                    }}
                    color="mockup.darkBlue"
                  >
                    STUDENT HELPER
                  </Typography>
                )}
              </Stack>
            </li>
          )}
        />
        <Stack direction="row" gap={1} flexWrap="wrap">
          {!!isSelected.length &&
            isSelected.map((item) => (
              <Stack
                key={item.id}
                sx={{
                  px: 1,
                  py: 0.5,
                  flexDirection: "row",
                  columnGap: 1,
                  backgroundColor: isTeacherAdmin(item)
                    ? theme.palette.mockup.neutral90
                    : theme.palette.mockup.primary40,
                  width: "fit-content",
                  borderRadius: 2,
                  alignItems: "end",
                  cursor: "pointer",
                }}
                onClick={() => handleClickOut(item.id)}
              >
                <Typography
                  variant="body3"
                  {...(!isTeacherAdmin(item) && { color: "mockup.darkBlue" })}
                >
                  {item.last_name.toUpperCase()}
                </Typography>
                <Box component="span" fontSize="14px">
                  x
                </Box>
              </Stack>
            ))}
        </Stack>
        {!!isSelected.length && (
          <Typography
            sx={{ cursor: "pointer" }}
            variant="body3"
            color="mockup.primary60"
            onClick={() => setIsSelected([])}
          >
            {t("adminDashboard.resetAll").toUpperCase()}
          </Typography>
        )}
      </Stack>
    </Tooltip>
  )
}
export default TeacherSearch
