import React, { useEffect, useState } from "react"

import { ReactComponent as IconExport } from "@common_assets/svg/export.svg"
import { ReactComponent as IconFilter } from "@common_assets/svg/filters.svg"
import { ReactComponent as IconTrash } from "@common_assets/svg/trash-circular.svg"
import {
  Stack,
  useTheme,
  Fab,
  Box,
  Popover,
  Grid,
  Button,
  Checkbox,
  Typography,
  CircularProgress,
} from "@mui/material"
import { useMutationSchoolTransactions } from "api/reactQuery/mutations/adminTeacher"
import {
  useInfiniteTeacherAdminSchoolTransactions,
  useQueryExportAdminTransactions,
  useQuerySchoolTransactionsSchoolData,
} from "api/reactQuery/queries/teacherAdmin"
import { TeacherAdminSchoolTransactionsResponse } from "api/reactQuery/queries/teacherAdmin.types"
import { AxiosResponse } from "axios"
import Coin from "components/common/icon/coin"
import BasicTable from "components/common/table/basicTable"
import TransactionType from "components/common/transactionType"
import { add, format, isValid } from "date-fns"
import { useBlobData } from "hooks/blobData"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useInView } from "react-intersection-observer"
import { InfiniteData, useQueryClient } from "react-query"
import {
  selectClassesId,
  selectHelpersId,
  selectSender,
  selectTeachersId,
  selectTitle,
  selectTransactionsType,
} from "store/adminTransaction/adminTransactions.selectors"
import {
  resetFilters,
  setClickedReset,
  setTransactionsType,
} from "store/adminTransaction/adminTransactions.slice"
import { selectUser } from "store/auth/auth.selectors"
import { getErrorMessage, getErrorMessageFromTab } from "utils/api"
import { schoolPlanToExportData } from "utils/export"

import Dialog from "../../../common/dialog/dialog"
import DialogUpgradeAccount from "../../../common/dialogUpgradeAccount/DialogUpgradeAccount"
import Autocomplete from "./autocomplete"
import DatePicker from "./datePicker"
import ModalContentFilters from "./modalContentFilters"
import { StudentProps } from "./SchoolTransactions.types"

interface DateRangeProps {
  from: Date | undefined
  to: Date | undefined
}

interface ShowSelectedOptions {
  title: string
  id: number | string
}

const SchoolTransactions = () => {
  const theme = useTheme()
  const isTablet = useIsBreakpointUp("desktop")
  const { showSnackbar } = useCustomSnackbar()
  const { ref, inView } = useInView({
    threshold: 0.5,
  })
  const userInfo = useAppSelector(selectUser)
  const [showSelectedOptions, setShowSelectedOptions] = useState<
    ShowSelectedOptions[]
  >([])
  const [selectedToDelete, setSelectedToDelete] = useState<number[]>([])
  const [isFetchExport, setIsFetchExport] = useState(false)
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()

  const classes = useAppSelector(selectClassesId)
  const teachers = useAppSelector(selectTeachersId)
  const transactionType = useAppSelector(selectTransactionsType)
  const title = useAppSelector(selectTitle)
  const studentHelpers = useAppSelector(selectHelpersId)
  const sender = useAppSelector(selectSender)

  const [selectedOptions, setSelectedOptions] = useState<StudentProps[]>([])
  const [dateRange, setDateRange] = useState<DateRangeProps>({
    from: undefined,
    to: undefined,
  })

  useEffect(() => {
    inView && fetchNextPage()
  }, [inView])

  const { payIt, isLoadingPayments } = useCustomPayment()

  const { popoverAnchorEl, handleClose, handleClick } =
    usePopover<HTMLButtonElement>()

  const {
    isOpen: isOpenExport,
    handleOpen: handleOpenExport,
    handleClose: handleCloseExport,
  } = useDialog()

  const { isOpen, handleOpen, handleClose: handleCloseDialog } = useDialog()

  const handleCloseCancel = () => {
    dispatch(setTransactionsType([]))
    handleClose()
  }

  const handleSelectedOptions = (option: StudentProps) => {
    setSelectedOptions((prev) => {
      const isSelected = prev.some((item) => item.id === option.id)
      if (isSelected) {
        return prev.filter((item) => item.id !== option.id)
      } else {
        return [...prev, option]
      }
    })
  }

  const { data: schoolData, isLoading: isLoadingSchoolData } =
    useQuerySchoolTransactionsSchoolData({
      options: {},
    })

  const { mutate } = useMutationSchoolTransactions({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "teacherAdminSchoolTransactionsInfinite",
        ])
        showSnackbar({
          title: "Transaction has been removed successful",
        })
        handleCloseDialog()
        setSelectedToDelete([])
      },
    },
  })

  /**
   *  TODO(check) - ustawić brak refetch bez poprawnej daty oraz zrobić req po opuszczeniu inputów
   * dane utrzymać poprzednie jakie były do momentu nowego fetcha
   */

  const { data, hasNextPage, fetchNextPage, refetch, isLoading } =
    useInfiniteTeacherAdminSchoolTransactions({
      limit: 20,
      skip: 0,
      classes: classes,
      teachers: teachers,
      students: selectedOptions?.map((student: StudentProps) => student.id),
      type_in: transactionType,
      title: !title.length ? undefined : title,
      student_helpers: studentHelpers,
      sender_type: !sender.length ? undefined : sender,
      date_from:
        dateRange.from !== undefined && isValid(dateRange.from)
          ? format(add(dateRange.from, { days: 1 }), "yyyy-MM-dd")
          : undefined,
      date_to:
        dateRange.to !== undefined && isValid(dateRange.to)
          ? format(add(dateRange.to, { days: 1 }), "yyyy-MM-dd")
          : undefined,
      options: {
        enabled: !!schoolData,
        onError: (err) => {
          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
        },
      },
    })

  const { isFetching, refetch: isFetchExportData } =
    useQueryExportAdminTransactions({
      classes: classes,
      teachers: teachers,
      students: selectedOptions?.map((student: StudentProps) => student.id),
      type_in: transactionType,
      title: !title.length ? undefined : title,
      student_helpers: studentHelpers,
      sender_type: !sender.length ? undefined : sender,
      date_from:
        dateRange.from !== undefined && isValid(dateRange.from)
          ? format(add(dateRange.from, { days: 1 }), "yyyy-MM-dd")
          : undefined,
      date_to:
        dateRange.to !== undefined && isValid(dateRange.to)
          ? format(add(dateRange.to, { days: 1 }), "yyyy-MM-dd")
          : undefined,
      options: {
        enabled: isFetchExport,
        onSuccess: (response) => {
          showSnackbar({
            title: "Transactions data has been successfully exported",
          })
          setIsFetchExport(false)
          useBlobData({
            response: response.data,
            classname: "Transactions",
            exportData: "School",
          })
        },
        onError: async (err) => {
          if (err.response?.data instanceof Blob) {
            const objectURL = URL.createObjectURL(err.response?.data)
            try {
              const response = await fetch(objectURL)
              const data = await response.json()
              showSnackbar({
                title: data.detail,
                variant: "error",
              })
            } catch (err) {}
            return
          }

          showSnackbar({
            title: getErrorMessage(err),
            variant: "error",
          })
        },
      },
    })

  const countSkippedStudents = (
    data?: InfiniteData<
      AxiosResponse<TeacherAdminSchoolTransactionsResponse[], unknown>
    >
  ): number => {
    return data?.pages.reduce((acc, item) => acc + item.data.length, 0) ?? 0
  }

  const handleChangeToggle = (
    data?: InfiniteData<
      AxiosResponse<TeacherAdminSchoolTransactionsResponse[], unknown>
    >
  ) => {
    const flatArr =
      data?.pages.flatMap((item) => item.data.map((el) => el.id)) ?? []
    if (selectedToDelete.length === countSkippedStudents(data)) {
      setSelectedToDelete([])
    } else {
      setSelectedToDelete(flatArr)
    }
  }

  const handleClickOut = (id: number | string) => {
    if (typeof id === "string") {
      const filterTransactionTypes = transactionType.filter(
        (idType) => idType !== id
      )
      dispatch(setTransactionsType(filterTransactionTypes))
      setTimeout(() => refetch(), 0)

      setDateRange((prev) => ({
        ...prev,
        [id]: undefined,
      }))
    }
    if (typeof id === "number") {
      setSelectedOptions((prev) => prev.filter((item) => item.id !== id))
    }
  }

  useEffect(() => {
    const dateEntries = Object.entries(dateRange).filter(
      ([, date]) => date !== undefined
    )

    const mappedDate = dateEntries.map(([key, value]) => {
      const formattedDate = value instanceof Date && format(value, "MM-dd-yyyy")
      return { title: `${key}: ${formattedDate}`, id: key }
    })

    const mappedSelectionOptions = selectedOptions.map((item) => ({
      title: `${item.first_name} ${item.last_name}`,
      id: item.id,
    }))

    const mappedTransactionsType = transactionType.map((item) => ({
      title: item,
      id: item,
    }))

    setShowSelectedOptions([
      ...mappedSelectionOptions,
      ...mappedDate,
      ...mappedTransactionsType,
    ])
  }, [selectedOptions, dateRange, transactionType])

  return (
    <Stack
      p={2}
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px 4px 0 0 ",
      }}
    >
      {isTablet ? (
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ marginBottom: 3 }}
        >
          <Grid item desktop mobile>
            <Autocomplete
              options={schoolData?.data.students ?? []}
              selectedOptions={selectedOptions}
              handleSelected={handleSelectedOptions}
            />
          </Grid>
          <Grid item desktop mobile>
            <DatePicker dateRange={dateRange} setDateRange={setDateRange} />
          </Grid>
          <Grid item desktop="auto" sx={{ minWidth: "120px", width: "120px" }}>
            <Box display="flex" justifyContent="flex-start" columnGap={1}>
              <Fab onClick={handleClick}>
                <IconFilter />
              </Fab>
              <Fab
                onClick={() => {
                  if (isFetching) return
                  schoolPlanToExportData({
                    user: userInfo,
                    upgrade: handleOpenExport,
                    exportData: isFetchExportData,
                  })
                }}
              >
                <span style={{ margin: "auto" }}>
                  {isFetching ? (
                    <CircularProgress />
                  ) : (
                    <IconExport
                      style={{
                        color: theme.palette.primary.main,
                        margin: "auto",
                      }}
                    />
                  )}
                </span>
              </Fab>
              <DialogUpgradeAccount
                open={isOpenExport}
                onClose={handleCloseExport}
                onActionButtonClick={() => {
                  payIt(null)
                }}
                isLoading={isLoadingPayments}
                isExport
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ marginBottom: 2 }}
          >
            <Grid item tablet mobile>
              <Autocomplete
                options={schoolData?.data.students ?? []}
                selectedOptions={selectedOptions}
                handleSelected={handleSelectedOptions}
              />
            </Grid>
            <Grid item tablet="auto" mobile="auto">
              <Fab onClick={handleClick}>
                <IconFilter />
              </Fab>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ marginBottom: 2 }}
          >
            <Grid item tablet mobile>
              <DatePicker dateRange={dateRange} setDateRange={setDateRange} />
            </Grid>
            <Grid item tablet="auto" mobile="auto">
              <Fab
                onClick={() => {
                  if (isFetching) return
                  schoolPlanToExportData({
                    user: userInfo,
                    upgrade: handleOpenExport,
                    exportData: isFetchExportData,
                  })
                }}
              >
                <span style={{ margin: "auto" }}>
                  {isFetching ? (
                    <CircularProgress />
                  ) : (
                    <IconExport
                      style={{
                        color: theme.palette.primary.main,
                        margin: "auto",
                      }}
                    />
                  )}
                </span>
              </Fab>
              <DialogUpgradeAccount
                open={isOpenExport}
                onClose={handleCloseExport}
                onActionButtonClick={() => {
                  payIt(null)
                }}
                isLoading={isLoadingPayments}
                isExport
              />
            </Grid>
          </Grid>
        </>
      )}
      <Popover
        anchorEl={popoverAnchorEl}
        open={!!popoverAnchorEl}
        onClose={() => handleCloseCancel()}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: "700px",
            marginTop: "5px",
          },
        }}
      >
        <ModalContentFilters />
        <Box textAlign="end" mr={2} mb={2}>
          <Button
            variant="text"
            onClick={() => {
              setSelectedOptions([])
              setDateRange({ to: undefined, from: undefined })
              dispatch(resetFilters())
              dispatch(setClickedReset(true))
              setTimeout(() => {
                refetch()
                dispatch(setClickedReset(false))
              }, 0)
            }}
          >
            Reset Filter
          </Button>
          <Button
            onClick={() => {
              refetch()
              handleClose()
            }}
          >
            Apply
          </Button>
        </Box>
      </Popover>
      <Stack direction="row" gap={1} flexWrap="wrap">
        {showSelectedOptions.map((item) => (
          <Stack
            key={item.id}
            sx={{
              px: 1,
              py: 0.5,
              flexDirection: "row",
              columnGap: 1,
              backgroundColor: theme.palette.mockup.neutral90,
              mb: 1,

              width: "fit-content",
              borderRadius: 2,
              alignItems: "end",
              cursor: "pointer",
            }}
            onClick={() => handleClickOut(item.id)}
          >
            <Typography variant="body3">{item.title.toUpperCase()}</Typography>
            <Box component="span" fontSize="14px">
              x
            </Box>
          </Stack>
        ))}
        {!!showSelectedOptions.length && (
          <Button
            onClick={() => {
              setSelectedOptions([])
              setDateRange({ to: undefined, from: undefined })
              dispatch(resetFilters())
              dispatch(setClickedReset(true))
              setTimeout(() => {
                refetch()
                dispatch(setClickedReset(false))
              }, 0)
            }}
            variant="text"
            sx={{
              color: theme.palette.mockup.primary60,
              height: "28px",
              fontSize: "12px",
              padding: "0 6px",
            }}
          >
            Reset Filters
          </Button>
        )}
      </Stack>
      <BasicTable
        topSlot={
          !!selectedToDelete.length && (
            <Stack
              sx={{
                alignItems: "end",
                backgroundColor: theme.palette.mockup.primary95,
              }}
            >
              <Button variant="text" onClick={handleOpen}>
                Delete Transaction{selectedToDelete.length > 1 && "S"}
              </Button>
            </Stack>
          )
        }
        head={
          <BasicTable.Row>
            <BasicTable.Cell>
              <Checkbox
                onChange={() => handleChangeToggle(data)}
                checked={
                  selectedToDelete.length === countSkippedStudents(data) &&
                  countSkippedStudents(data) !== 0
                }
                indeterminate={
                  selectedToDelete.length !== countSkippedStudents(data) &&
                  selectedToDelete.length !== 0
                }
              />
            </BasicTable.Cell>
            <BasicTable.Cell>
              <BasicTable.HeadText>Type</BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell>
              <BasicTable.HeadText>Class & Teachers</BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell>
              <BasicTable.HeadText>Student</BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell>
              <BasicTable.HeadText>Title</BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell>
              <BasicTable.HeadText>Date</BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell>
              <BasicTable.HeadText>Amount</BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell />
          </BasicTable.Row>
        }
        body={data?.pages.flatMap((data) =>
          data.data.map((transaction) => (
            <BasicTable.Row key={transaction.id}>
              <BasicTable.Cell>
                <Checkbox
                  checked={selectedToDelete.some(
                    (item) => item === transaction.id
                  )}
                  onChange={() =>
                    setSelectedToDelete((prev) => {
                      return prev.includes(transaction.id)
                        ? prev.filter((item) => item !== transaction.id)
                        : [...prev, transaction.id]
                    })
                  }
                />
              </BasicTable.Cell>
              <BasicTable.Cell>
                <TransactionType transaction={transaction} />
              </BasicTable.Cell>
              <BasicTable.Cell>
                <Stack>
                  <Typography fontWeight="500" variant="body2">
                    {transaction.class_name}
                  </Typography>
                  <Typography fontWeight="500" variant="body2">
                    {transaction.teacher?.last_name}
                  </Typography>
                </Stack>
              </BasicTable.Cell>
              <BasicTable.Cell>
                <Typography fontWeight="500" variant="body2">
                  {transaction.student.first_name}{" "}
                  {transaction.student.last_name}
                </Typography>
              </BasicTable.Cell>
              <BasicTable.Cell>
                <Typography fontWeight="500" variant="body2">
                  {transaction.title}
                </Typography>
              </BasicTable.Cell>
              <BasicTable.Cell>
                <Typography fontWeight="500" variant="body2">
                  {transaction?.created_at &&
                    format(new Date(transaction.created_at), "MMM dd, yyyy")}
                </Typography>
              </BasicTable.Cell>
              <BasicTable.Cell>
                <Coin
                  size="small"
                  amount={Math.abs(transaction.amount)}
                  sign={transaction.amount > 0 ? "+" : "-"}
                />
              </BasicTable.Cell>
              <BasicTable.Cell>
                <IconTrash
                  color={theme.palette.mockup.primary60}
                  style={{ cursor: "pointer" }}
                  onClick={() => mutate({ transaction_ids: [transaction.id] })}
                />
              </BasicTable.Cell>
            </BasicTable.Row>
          ))
        )}
        bottomSlot={
          <>
            {(isLoading || isLoadingSchoolData) && (
              <Box
                component="span"
                sx={{ display: "block", textAlign: "center", marginTop: 2 }}
              >
                <CircularProgress />
              </Box>
            )}
            {!isLoading &&
              !isLoadingSchoolData &&
              !data?.pages?.flatMap((page) => page.data)?.length && (
                <Typography m={2}>
                  No results matching the selected filtering criteria.
                </Typography>
              )}
            {hasNextPage && <div ref={ref} />}
          </>
        }
      />
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        onActionButtonClick={() =>
          mutate({
            transaction_ids: selectedToDelete,
          })
        }
        titleText={`Are you sure you want delete ${
          selectedToDelete.length
        } transaction${selectedToDelete.length > 1 ? "s" : ""} ?`}
      />
    </Stack>
  )
}
export default SchoolTransactions
