import { AppBar, Box, Stack, Toolbar } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useIsMatchingClassRoutes } from "hooks/navigation"
import { useUserRole } from "hooks/user"
import { useLocation } from "react-router"
import { UserRole } from "ts/enums/User"

import NavigationTabs from "./navigationTabs"
import NavigationTabsPresets from "./navigationTabsPresets"
import SubNavigationStudent from "./subNavigationStudent"
import SubNavigationTeacher from "./subNavigationTeacher"
import UserData from "./userData"

const Header = () => {
  const isMatchingClassRoutes = useIsMatchingClassRoutes()
  const { pathname } = useLocation()
  const isTablet = useIsBreakpointUp("tablet")
  const userRole = useUserRole()

  return (
    <AppBar position="sticky">
      <Toolbar
        sx={{
          border: (theme) => `1px solid ${theme.palette.mockup.primary95}`,
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          {userRole === UserRole.TEACHER && <SubNavigationTeacher />}
          {userRole === UserRole.STUDENT && <SubNavigationStudent />}
          <UserData />
        </Stack>
      </Toolbar>
      {isMatchingClassRoutes && !isTablet && (
        <Box height="40px">
          <Box
            px="16px"
            pt="12px"
            bgcolor="mockup.primary95"
            position="absolute"
            width="100%"
          >
            <NavigationTabs />
          </Box>
        </Box>
      )}
      {pathname.includes("presets") && !isTablet && (
        <Box height="40px">
          <Box
            px="16px"
            pt="12px"
            bgcolor="mockup.primary95"
            position="absolute"
            width="100%"
          >
            <NavigationTabsPresets />
          </Box>
        </Box>
      )}
    </AppBar>
  )
}

export default Header
