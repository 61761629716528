import React, { useEffect, useState } from "react"

import { Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation, useMatch } from "react-router"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"

import { ToggleButton } from "./TabNavigation.utility"

interface TabNavigationProps {
  render: (isAdmin: boolean) => React.ReactNode
}

const TabNavigation = ({ render }: TabNavigationProps) => {
  const { t } = useTranslation()
  const [isAdmin, setIsAdmin] = useState(!!useMatch(`${RoutePath.ADMIN}/*`))
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.startsWith("/admin")) {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }
  }, [location])

  const handleRoute = (isAdmin: boolean) => {
    setIsAdmin(isAdmin)
    navigate(isAdmin ? RoutePath.ADMIN : RoutePath.HOMEPAGE)
  }

  return (
    <>
      <Stack
        sx={(theme) => ({
          backgroundColor: theme.palette.mockup.primary20,
          borderRadius: "4px",
          cursor: "pointer",
        })}
      >
        <Stack
          flexDirection="row"
          m="4px"
          justifyContent="space-between"
          alignItems="center"
        >
          <ToggleButton isActive={!isAdmin} onClick={() => handleRoute(false)}>
            {t("adminDashboard.teacher")}
          </ToggleButton>
          <ToggleButton isActive={isAdmin} onClick={() => handleRoute(true)}>
            {t("adminDashboard.admin")}
          </ToggleButton>
        </Stack>
      </Stack>
      {render(isAdmin)}
    </>
  )
}
export default TabNavigation
