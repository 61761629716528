import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import axiosInstance, { Endpoint } from "../../api"
import {
  AdminTransactionsTypes,
  TypeIn,
  SenderType,
} from "./adminTransactions.types"

const initialState: AdminTransactionsTypes = {
  classesId: [],
  teachersId: [],
  helpersId: [],
  transactionsType: [],
  title: "",
  sender: "",
  isAdmin: undefined,
  isClickedReset: false,
}

export const fetchIsAdmin = createAsyncThunk("user/me", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance(Endpoint.USER_ME)
    if (response.data.type === "teacher") {
      return response.data.user.is_school_admin
    } else {
      return response.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err)
  }
})

export const adminTransactionsSlice = createSlice({
  name: "adminTransactions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      fetchIsAdmin.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.isAdmin = !action.payload
      }
    )
  },
  reducers: {
    setClassesId: (state, action: PayloadAction<number[]>) => {
      state.classesId = action.payload
    },
    setTeachersId: (state, action: PayloadAction<number[]>) => {
      state.teachersId = action.payload
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload
    },
    setTransactionsType: (state, action: PayloadAction<TypeIn[]>) => {
      state.transactionsType = action.payload
    },
    setSender: (state, action: PayloadAction<SenderType>) => {
      state.sender = action.payload
    },
    setHelpersId: (state, action: PayloadAction<number[]>) => {
      state.helpersId = action.payload
    },
    resetFilters: () => initialState,
    setClickedReset: (state, action: PayloadAction<boolean>) => {
      state.isClickedReset = action.payload
    },
  },
})

export const {
  setClassesId,
  setTeachersId,
  setTitle,
  setTransactionsType,
  setSender,
  setHelpersId,
  resetFilters,
  setClickedReset,
} = adminTransactionsSlice.actions
export default adminTransactionsSlice.reducer
