import { FC } from "react"

import { ItemVariants } from "api/reactQuery/queries/teacherAdmin.types"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useLocation } from "react-router"
import { TransactionTypeShort } from "ts/types/Transaction"

import Listing from "./listing"
import Table from "./table"

interface IProps {
  variant: TransactionTypeShort | ItemVariants
}

const SetupClassTeacher: FC<IProps> = ({ variant }) => {
  const isTablet = useIsBreakpointUp("tablet")
  const location = useLocation()
  const isAdmin = location.pathname.includes("admin")

  return (
    <>
      {isTablet ? (
        <Table variant={variant} {...{ isAdmin }} />
      ) : (
        <Listing variant={variant} {...{ isAdmin }} />
      )}
    </>
  )
}

export default SetupClassTeacher
