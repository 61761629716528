import React, { useEffect, useState } from "react"

import {
  Autocomplete,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { TeacherAdminSchoolTransactionsSchoolDataResponse } from "api/reactQuery/queries/teacherAdmin.types"
import { ReactComponent as IconSearch } from "assets/common/svg/search.svg"
import { AxiosResponse } from "axios"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import {
  selectClassesId,
  selectIsClickedReset,
} from "store/adminTransaction/adminTransactions.selectors"
import { setClassesId } from "store/adminTransaction/adminTransactions.slice"

interface ClassType {
  id: number
  name: string
}

const ClassSearch = () => {
  const queryClient = useQueryClient()
  const initialState = useAppSelector(selectClassesId)
  const isShouldReset = useAppSelector(selectIsClickedReset)

  const data = queryClient.getQueryData<
    AxiosResponse<TeacherAdminSchoolTransactionsSchoolDataResponse>
  >(["schoolData"])

  const fullClasses = (initial: number[]) =>
    data?.data.classes.filter((classesData) =>
      initial.includes(classesData.id)
    ) || []

  const [selectedOptions, setSelectedOptions] = useState<ClassType[]>(
    fullClasses(initialState)
  )

  useEffect(() => {
    if (isShouldReset) {
      setSelectedOptions([])
    }
  }, [isShouldReset])

  const availableOptions = data?.data.classes.filter(
    (option: ClassType) =>
      !selectedOptions.some((selected) => selected.id === option.id)
  )
  const dispatch = useAppDispatch()

  const theme = useTheme()
  const { t } = useTranslation()
  const [searchClasses, setSearchClasses] = useState<ClassType | null>(null)

  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    const currentId = selectedOptions.map((item) => item.id)
    dispatch(setClassesId(currentId))
  }, [selectedOptions])

  const handleSelectedOptions = (
    event: React.MouseEvent,
    option: ClassType
  ) => {
    event.preventDefault()
    setSelectedOptions((prev) => [...prev, option])
    setSearchClasses(null)
  }

  const handleClickOut = (id: number) => {
    setSelectedOptions((prev) => prev.filter((item) => item.id !== id))
  }

  return (
    <Stack rowGap={1}>
      <Typography>{t("adminDashboard.class")}</Typography>
      <Autocomplete<ClassType, false>
        value={searchClasses}
        onChange={(_, newValue) => {
          setSearchClasses(newValue)
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              variant="outlined"
              label={t("adminDashboard.searchForStudents")}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      style={{ color: theme.palette.mockup.primary60 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  transform: "translate(40px, 16px) scale(1)",
                  "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(14px, -9px) scale(0.75)",
                  },
                },
              }}
              InputLabelProps={{
                shrink: isFocus,
              }}
            />
          )
        }}
        options={availableOptions ?? []}
        renderOption={(props, option) => {
          const { ...optionProps } = props

          return (
            <li
              {...optionProps}
              onClick={(event) => handleSelectedOptions(event, option)}
            >
              {option.name}
            </li>
          )
        }}
      />
      <Stack flexDirection="row" gap={1} flexWrap="wrap">
        {!!selectedOptions.length &&
          selectedOptions.map((item) => (
            <Stack
              key={item.id}
              sx={{
                px: 1,
                py: 0.5,
                flexDirection: "row",
                columnGap: 1,
                backgroundColor: theme.palette.mockup.neutral90,
                width: "fit-content",
                borderRadius: 2,
                alignItems: "end",
                cursor: "pointer",
              }}
              onClick={() => handleClickOut(item.id)}
            >
              <Typography variant="body3">{item.name.toUpperCase()}</Typography>
              <Box component="span" fontSize="14px">
                x
              </Box>
            </Stack>
          ))}
      </Stack>
      {!!selectedOptions.length && (
        <Typography
          sx={{ cursor: "pointer", whiteSpace: "nowrap" }}
          variant="body3"
          color="mockup.primary60"
          onClick={() => setSelectedOptions([])}
        >
          {t("adminDashboard.resetAll").toUpperCase()}
        </Typography>
      )}
    </Stack>
  )
}
export default ClassSearch
