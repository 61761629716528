import { ReactComponent as IconEmailOutlined } from "@common_assets/svg/at-email-outlined.svg"
import { ReactComponent as IconEmailSolid } from "@common_assets/svg/at-email-solid.svg"
import { ReactComponent as IconBasketOutlined } from "@common_assets/svg/basket-outlined.svg"
import { ReactComponent as IconBasketSolid } from "@common_assets/svg/basket-solid.svg"
import { ReactComponent as IconBeakerOutlined } from "@common_assets/svg/beaker-outlined.svg"
import { ReactComponent as IconBeakerSolid } from "@common_assets/svg/beaker-solid.svg"
import { ReactComponent as IconBellOutlined } from "@common_assets/svg/bell-outlined.svg"
import { ReactComponent as IconBellSolid } from "@common_assets/svg/bell-solid.svg"
import { ReactComponent as IconBill } from "@common_assets/svg/bill-outlined.svg"
import { ReactComponent as IconBillOutlined } from "@common_assets/svg/bill.svg"
import { ReactComponent as IconBoltOutlined } from "@common_assets/svg/bolt-outlined.svg"
import { ReactComponent as IconBoltSolid } from "@common_assets/svg/bolt-solid.svg"
import { ReactComponent as IconBoneOutlined } from "@common_assets/svg/bone-outlined.svg"
import { ReactComponent as IconBoneSolid } from "@common_assets/svg/bone-solid.svg"
import { ReactComponent as IconCalendarClockOutlined } from "@common_assets/svg/calendar-clock-outlined.svg"
import { ReactComponent as IconCalendarClockSolid } from "@common_assets/svg/calendar-clock-solid.svg"
import { ReactComponent as IconCameraOutlined } from "@common_assets/svg/camera-outlined.svg"
import { ReactComponent as IconCameraSolid } from "@common_assets/svg/camera-solid.svg"
import { ReactComponent as IconClipOutlined } from "@common_assets/svg/clip-outlined.svg"
import { ReactComponent as IconClipSolid } from "@common_assets/svg/clip-solid.svg"
import { ReactComponent as IconCompasOutlined } from "@common_assets/svg/compas-outlined.svg"
import { ReactComponent as IconCompasSolid } from "@common_assets/svg/compas-solid.svg"
import { ReactComponent as IconCrownOutlined } from "@common_assets/svg/crown-outlined.svg"
import { ReactComponent as IconCrownSolid } from "@common_assets/svg/crown-solid.svg"
import { ReactComponent as IconDnaOutlined } from "@common_assets/svg/dna-outlined.svg"
import { ReactComponent as IconDnaSolid } from "@common_assets/svg/dna-solid.svg"
import { ReactComponent as IconDocumentOutlined } from "@common_assets/svg/document-outlined.svg"
import { ReactComponent as IconDocumentSolid } from "@common_assets/svg/document-solid.svg"
import { ReactComponent as IconDrawOutlined } from "@common_assets/svg/draw-outlined.svg"
import { ReactComponent as IconDrawSolid } from "@common_assets/svg/draw-solid.svg"
import { ReactComponent as IconExploreOutlined } from "@common_assets/svg/explore-outlined.svg"
import { ReactComponent as IconExploreSolid } from "@common_assets/svg/explore-solid.svg"
import { ReactComponent as IconFilters } from "@common_assets/svg/filters-solid.svg"
import { ReactComponent as IconFlagOutlined } from "@common_assets/svg/flag-outlined.svg"
import { ReactComponent as IconFlagSolid } from "@common_assets/svg/flag-solid.svg"
import { ReactComponent as IconGiftOutlined } from "@common_assets/svg/gift-outlined.svg"
import { ReactComponent as IconGiftSolid } from "@common_assets/svg/gift-solid.svg"
import { ReactComponent as IconGlobalOutlined } from "@common_assets/svg/global-outlined.svg"
import { ReactComponent as IconGlobalSolid } from "@common_assets/svg/global-solid.svg"
import { ReactComponent as IconGridOutlined } from "@common_assets/svg/grid-outlined.svg"
import { ReactComponent as IconGridSolid } from "@common_assets/svg/grid-solid.svg"
import { ReactComponent as IconHeartOutlined } from "@common_assets/svg/heart-outlined.svg"
import { ReactComponent as IconHeartSolid } from "@common_assets/svg/heart-solid.svg"
import { ReactComponent as IconHomeOutlined } from "@common_assets/svg/home-outlined.svg"
import { ReactComponent as IconHomeSolid } from "@common_assets/svg/home-solid.svg"
import { ReactComponent as IconHourglassOutlined } from "@common_assets/svg/hourglass-outlined.svg"
import { ReactComponent as IconHourglassSolid } from "@common_assets/svg/hourglass-solid.svg"
import { ReactComponent as IconIceOutlined } from "@common_assets/svg/ice-outlined.svg"
import { ReactComponent as IconIceSolid } from "@common_assets/svg/ice-solid.svg"
import { ReactComponent as IconImageOutlined } from "@common_assets/svg/image-outlined.svg"
import { ReactComponent as IconImageSolid } from "@common_assets/svg/image-solid.svg"
import { ReactComponent as IconKeyOutlined } from "@common_assets/svg/key-outlined.svg"
import { ReactComponent as IconKeySolid } from "@common_assets/svg/key-solid.svg"
import { ReactComponent as IconMedkitOutlined } from "@common_assets/svg/medkit-outlined.svg"
import { ReactComponent as IconMedkitSolid } from "@common_assets/svg/medkit-solid.svg"
import { ReactComponent as IconMedsOutlined } from "@common_assets/svg/meds-outlined.svg"
import { ReactComponent as IconMedsSolid } from "@common_assets/svg/meds-solid.svg"
import { ReactComponent as IconMonitorOutlined } from "@common_assets/svg/monitor-outlined.svg"
import { ReactComponent as IconMonitorSolid } from "@common_assets/svg/monitor-solid.svg"
import { ReactComponent as IconMoonOutlined } from "@common_assets/svg/moon-outlined.svg"
import { ReactComponent as IconMoonSolid } from "@common_assets/svg/moon-solid.svg"
import { ReactComponent as IconMultipleOutlined } from "@common_assets/svg/multiple-outlined.svg"
import { ReactComponent as IconMultipleSolid } from "@common_assets/svg/multiple-solid.svg"
import { ReactComponent as IconNoteOutlined } from "@common_assets/svg/note-outlined.svg"
import { ReactComponent as IconNoteSolid } from "@common_assets/svg/note-solid.svg"
import { ReactComponent as IconOfferOutlined } from "@common_assets/svg/offer-outlined.svg"
import { ReactComponent as IconOfferSolid } from "@common_assets/svg/offer-solid.svg"
import { ReactComponent as IconPhoneOutlined } from "@common_assets/svg/phone-outlined.svg"
import { ReactComponent as IconPhoneSolid } from "@common_assets/svg/phone-solid.svg"
import { ReactComponent as IconPinOutlined } from "@common_assets/svg/pin-outlined.svg"
import { ReactComponent as IconPinSolid } from "@common_assets/svg/pin-solid.svg"
import { ReactComponent as IconQuestionmarkOutlined } from "@common_assets/svg/questionmark-outlined.svg"
import { ReactComponent as IconQuestionmarkSolid } from "@common_assets/svg/questionmark-solid.svg"
import { ReactComponent as IconRoundplusOutlined } from "@common_assets/svg/roundplus-outlined.svg"
import { ReactComponent as IconRoundplusSolid } from "@common_assets/svg/roundplus-solid.svg"
import { ReactComponent as IconShare1Outlined } from "@common_assets/svg/share-1-outlined.svg"
import { ReactComponent as IconShare1Solid } from "@common_assets/svg/share-1-solid.svg"
import { ReactComponent as IconShieldCrossOutlined } from "@common_assets/svg/shield-cross-outlined.svg"
import { ReactComponent as IconShieldCrossSolid } from "@common_assets/svg/shield-cross-solid.svg"
import { ReactComponent as IconShirtOutlined } from "@common_assets/svg/shirt-outlined.svg"
import { ReactComponent as IconShirtSolid } from "@common_assets/svg/shirt-solid.svg"
import { ReactComponent as IconShootTargetOutlined } from "@common_assets/svg/shoottarget-outlined.svg"
import { ReactComponent as IconShootTargetSolid } from "@common_assets/svg/shoottarget-solid.svg"
import { ReactComponent as IconStarOutlined } from "@common_assets/svg/star-outlined.svg"
import { ReactComponent as IconStarSolid } from "@common_assets/svg/star-solid.svg"
import { ReactComponent as IconStopwatchOutlined } from "@common_assets/svg/stopwatch-outlined.svg"
import { ReactComponent as IconStopwatchSolid } from "@common_assets/svg/stopwatch-solid.svg"
import { ReactComponent as IconSuitcaseOutlined } from "@common_assets/svg/suitcase-outlined.svg"
import { ReactComponent as IconSuitcaseSolid } from "@common_assets/svg/suitcase-solid.svg"
import { ReactComponent as IconSunOutlined } from "@common_assets/svg/sun-outlined.svg"
import { ReactComponent as IconSunSolid } from "@common_assets/svg/sun-solid.svg"
import { ReactComponent as IconTubeOutlined } from "@common_assets/svg/test-tube-outlined.svg"
import { ReactComponent as IconTubeSolid } from "@common_assets/svg/test-tube-solid.svg"
import { ReactComponent as IconTextLineOutlined } from "@common_assets/svg/text-line-outlined.svg"
import { ReactComponent as IconTextLineSolid } from "@common_assets/svg/text-line-solid.svg"
import { ReactComponent as IconToothOutlined } from "@common_assets/svg/tooth-outlined.svg"
import { ReactComponent as IconToothSolid } from "@common_assets/svg/tooth-solid.svg"
import { ReactComponent as IconTrophyOutlined } from "@common_assets/svg/trophy-outlined.svg"
import { ReactComponent as IconTrophySolid } from "@common_assets/svg/trophy-solid.svg"
import { ReactComponent as IconUmbrellaOutlined } from "@common_assets/svg/umbrella-outlined.svg"
import { ReactComponent as IconUmbrellaSolid } from "@common_assets/svg/umbrella-solid.svg"
import { ReactComponent as IconUserCheckOutlined } from "@common_assets/svg/user-check-outlined.svg"
import { ReactComponent as IconUserCheckSolid } from "@common_assets/svg/user-check-solid.svg"
import { ReactComponent as IconWalletOutlined } from "@common_assets/svg/wallet-outlined.svg"
import { ReactComponent as IconWalletSolid } from "@common_assets/svg/wallet-solid.svg"
import { ReactComponent as IconWandOutlined } from "@common_assets/svg/wand-outlined.svg"
import { ReactComponent as IconWandSolid } from "@common_assets/svg/wand-solid.svg"
import { ReactComponent as IconWaterdropOutlined } from "@common_assets/svg/waterdrop-outlined.svg"
import { ReactComponent as IconWaterdropSolid } from "@common_assets/svg/waterdrop-solid.svg"

export const icons = {
  bell: {
    outlined: IconBellOutlined,
    solid: IconBellSolid,
  },
  bill: {
    outlined: IconBillOutlined,
    solid: IconBill,
  },
  bolt: {
    outlined: IconBoltOutlined,
    solid: IconBoltSolid,
  },
  bone: {
    outlined: IconBoneOutlined,
    solid: IconBoneSolid,
  },
  crown: {
    outlined: IconCrownOutlined,
    solid: IconCrownSolid,
  },
  dna: {
    outlined: IconDnaOutlined,
    solid: IconDnaSolid,
  },
  document: {
    outlined: IconDocumentOutlined,
    solid: IconDocumentSolid,
  },
  flag: {
    outlined: IconFlagOutlined,
    solid: IconFlagSolid,
  },
  gift: {
    outlined: IconGiftOutlined,
    solid: IconGiftSolid,
  },
  grid: {
    outlined: IconGridOutlined,
    solid: IconGridSolid,
  },
  heart: {
    outlined: IconHeartOutlined,
    solid: IconHeartSolid,
  },
  home: {
    outlined: IconHomeOutlined,
    solid: IconHomeSolid,
  },
  hourglass: {
    outlined: IconHourglassOutlined,
    solid: IconHourglassSolid,
  },
  ice: {
    outlined: IconIceOutlined,
    solid: IconIceSolid,
  },
  image: {
    outlined: IconImageOutlined,
    solid: IconImageSolid,
  },
  key: {
    outlined: IconKeyOutlined,
    solid: IconKeySolid,
  },
  medkit: {
    outlined: IconMedkitOutlined,
    solid: IconMedkitSolid,
  },
  moon: {
    outlined: IconMoonOutlined,
    solid: IconMoonSolid,
  },
  shirt: {
    outlined: IconShirtOutlined,
    solid: IconShirtSolid,
  },
  star: {
    outlined: IconStarOutlined,
    solid: IconStarSolid,
  },
  stopwatch: {
    outlined: IconStopwatchOutlined,
    solid: IconStopwatchSolid,
  },
  suitcase: {
    outlined: IconSuitcaseOutlined,
    solid: IconSuitcaseSolid,
  },
  sun: {
    outlined: IconSunOutlined,
    solid: IconSunSolid,
  },
  tooth: {
    outlined: IconToothOutlined,
    solid: IconToothSolid,
  },
  trophy: {
    outlined: IconTrophyOutlined,
    solid: IconTrophySolid,
  },
  umbrella: {
    outlined: IconUmbrellaOutlined,
    solid: IconUmbrellaSolid,
  },
  wallet: {
    outlined: IconWalletOutlined,
    solid: IconWalletSolid,
  },
  wand: {
    outlined: IconWandOutlined,
    solid: IconWandSolid,
  },
  waterdrop: {
    outlined: IconWaterdropOutlined,
    solid: IconWaterdropSolid,
  },
  basket: {
    outlined: IconBasketOutlined,
    solid: IconBasketSolid,
  },
  calendarclock: {
    outlined: IconCalendarClockOutlined,
    solid: IconCalendarClockSolid,
  },
  camera: {
    outlined: IconCameraOutlined,
    solid: IconCameraSolid,
  },
  clip: {
    outlined: IconClipOutlined,
    solid: IconClipSolid,
  },
  compass: {
    outlined: IconCompasOutlined,
    solid: IconCompasSolid,
  },
  draw: {
    outlined: IconDrawOutlined,
    solid: IconDrawSolid,
  },
  explore: {
    outlined: IconExploreOutlined,
    solid: IconExploreSolid,
  },
  global: {
    outlined: IconGlobalOutlined,
    solid: IconGlobalSolid,
  },
  multiple: {
    outlined: IconMultipleOutlined,
    solid: IconMultipleSolid,
  },
  note: {
    outlined: IconNoteOutlined,
    solid: IconNoteSolid,
  },
  offer: {
    outlined: IconOfferOutlined,
    solid: IconOfferSolid,
  },
  phone: {
    outlined: IconPhoneOutlined,
    solid: IconPhoneSolid,
  },
  pin: {
    outlined: IconPinOutlined,
    solid: IconPinSolid,
  },
  questionmark: {
    outlined: IconQuestionmarkOutlined,
    solid: IconQuestionmarkSolid,
  },
  roundplus: {
    outlined: IconRoundplusOutlined,
    solid: IconRoundplusSolid,
  },
  share: {
    outlined: IconShare1Outlined,
    solid: IconShare1Solid,
  },
  shieldcross: {
    outlined: IconShieldCrossOutlined,
    solid: IconShieldCrossSolid,
  },
  shoottarget: {
    outlined: IconShootTargetOutlined,
    solid: IconShootTargetSolid,
  },
  textline: {
    outlined: IconTextLineOutlined,
    solid: IconTextLineSolid,
  },
  monitor: {
    outlined: IconMonitorOutlined,
    solid: IconMonitorSolid,
  },
  beaker: {
    outlined: IconBeakerOutlined,
    solid: IconBeakerSolid,
  },
  tube: {
    outlined: IconTubeOutlined,
    solid: IconTubeSolid,
  },
  email: {
    outlined: IconEmailOutlined,
    solid: IconEmailSolid,
  },
  meds: {
    outlined: IconMedsOutlined,
    solid: IconMedsSolid,
  },
  userCheck: {
    outlined: IconUserCheckOutlined,
    solid: IconUserCheckSolid,
  },
  filters: {
    outlined: IconFilters,
    solid: IconFilters,
  },
}
