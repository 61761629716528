import { IconType } from "ts/types/Icon"

export interface IResponseNewClass {
  name: string
  description: string
  grade: string
  icon: IconType | null
  paycheck_automatic: boolean
  expense_automatic: boolean
  savings_account_option: boolean
  interest_option: boolean
  interest_granting_interval: string | null
  saving_interest_rate: number | string
  round_interest_to_whole_number: boolean
  expense_interval: string | null
  paycheck_interval: string | null
  copy_settings?: boolean
  copy_from_id?: number | string
  id?: number
  is_class_visible: boolean
}

export enum Paycheck_interval {
  WEEKLY = "weekly",
  BIWEEKLY = "biweekly",
  MONTHLY = "monthly",
}
