import React from "react"

import { Stack } from "@mui/material"
import DynamicIcon from "components/common/icon/dynamicIcon"
import { useTranslation } from "react-i18next"
import { RoutePath } from "routes/Route.types"

import NavItem from "./navItem/NavItem"

const AdminAside = () => {
  const { t } = useTranslation()

  return (
    <Stack mt={3}>
      <NavItem
        name={t("adminDashboard.schoolTransactions")}
        to={RoutePath.ADMIN}
        Icon={<DynamicIcon name="document" variant="outlined" />}
        IconActive={<DynamicIcon name="document" variant="solid" />}
      />
      <NavItem
        name={t("adminDashboard.teachers")}
        to={RoutePath.ADMIN_TEACHERS}
        Icon={<DynamicIcon name="userCheck" variant="outlined" />}
        IconActive={<DynamicIcon name="userCheck" variant="solid" />}
      />
      <NavItem
        name={t("adminDashboard.presets")}
        to={RoutePath.ADMIN_PRESETS}
        Icon={<DynamicIcon name="filters" variant="outlined" />}
        IconActive={<DynamicIcon name="filters" variant="solid" />}
      />
    </Stack>
  )
}
export default AdminAside
