import { RootState } from "../store.types"

export const selectClassesId = (state: RootState) =>
  state.adminTransactions.classesId

export const selectTeachersId = (state: RootState) =>
  state.adminTransactions.teachersId

export const selectTitle = (state: RootState) => state.adminTransactions.title
export const selectTransactionsType = (state: RootState) =>
  state.adminTransactions.transactionsType

export const selectSender = (state: RootState) => state.adminTransactions.sender
export const selectHelpersId = (state: RootState) =>
  state.adminTransactions.helpersId

export const selectIsClickedReset = (state: RootState) =>
  state.adminTransactions.isClickedReset
