import { useQueryClassesTransactionItems } from "../../../../api/reactQuery/queries/classesTransactionItems"
import { useQueryItemsAdmin } from "../../../../api/reactQuery/queries/teacherAdmin"
import { ItemVariants } from "../../../../api/reactQuery/queries/teacherAdmin.types"
import { TransactionTypeShort } from "../../../../ts/types/Transaction"

interface IProps {
  classId?: string
  variant: TransactionTypeShort | ItemVariants
  isAdmin: boolean
}

export const useItemStoresHook = ({ variant, classId, isAdmin }: IProps) => {
  if (isAdmin) {
    const { data, isLoading, isError } = useQueryItemsAdmin({
      variant: variant as ItemVariants,
      options: {
        enabled: isAdmin,
      },
    })
    return { data, isLoading, isError }
  } else {
    const { data, isLoading, isError } = useQueryClassesTransactionItems({
      classId: Number(classId),
      variant,
      options: {
        enabled: typeof Number(classId) === "number" && !isAdmin,
      },
    })
    return { data, isLoading, isError }
  }
}
