export const adminDashboard = {
  teacher: "Teacher",
  teachers: "Teachers",
  admin: "Admin",
  schoolTransactions: "School transactions",
  presets: "Presets",
  searchForStudents: "Search for students",
  class: "Class",
  resetAll: "Reset all",
  studentHelper: "Student Helper",
  searchForTeachersAndStudentHelpers: "Search for teachers and student helpers",
  typeOfTransaction: "Type of transaction",
  title: "Title",
  titleIncluding: "Title including",
  sender: "Sender",
  bonuses: "Bonuses",
  fines: "Fines",
  bills: "Bills",
  searchForTeachers: "Search for teachers",
  active: "Active",
  pending: "Pending",
}
