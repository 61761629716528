import { FC, ReactNode } from "react"

import { Stack, Tooltip, Typography } from "@mui/material"
import Coin from "components/common/icon/coin"
import { useTranslation } from "react-i18next"

import ItemWrapper from "../itemWrapper"

interface IProps {
  topTextTitle: string
  topText: string
  bottomTextTitle: string
  bottomText: string | number
  value?: number
  actionButtonsSlot: ReactNode
  classText?: string
  classTextTitle?: string
  labelItem?: ReactNode
}

interface IPropsNoBottom
  extends Omit<IProps, "bottomTextTitle" | "bottomText"> {
  bottomTextTitle?: never
  bottomText?: never
}

const BasicItem: FC<IProps | IPropsNoBottom> = ({
  topTextTitle,
  topText,
  bottomTextTitle,
  bottomText,
  value,
  classText,
  classTextTitle,
  actionButtonsSlot,
  labelItem,
}) => {
  const { t } = useTranslation()

  return (
    <ItemWrapper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
      >
        <Stack>
          <Typography variant="body3" color="mockup.neutral40" fontWeight={500}>
            {topTextTitle}
          </Typography>
          <Stack direction="row" flexWrap="wrap" columnGap={1}>
            <Typography
              variant="body1"
              color="mockup.neutral10"
              sx={{ wordBreak: "break-all" }}
            >
              {topText}
            </Typography>
            {labelItem}
          </Stack>
        </Stack>
        {actionButtonsSlot}
      </Stack>
      {((bottomTextTitle && bottomText) || value) && (
        <Stack direction="row" justifyContent="space-between" gap="16px">
          {bottomTextTitle && bottomText.toString() && (
            <Stack
              direction="row"
              columnGap="16px"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <Stack>
                <Typography
                  variant="body3"
                  color="mockup.neutral40"
                  fontWeight={500}
                >
                  {bottomTextTitle}
                </Typography>
                <Typography
                  variant="body2"
                  color="mockup.neutral20"
                  fontWeight={500}
                >
                  {bottomText}
                </Typography>
              </Stack>
              {classText && classTextTitle && (
                <Stack
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography
                    variant="body3"
                    color="mockup.neutral40"
                    fontWeight={500}
                  >
                    {classTextTitle}
                  </Typography>
                  <Tooltip title={classText}>
                    <Typography
                      noWrap
                      variant="body2"
                      color="mockup.neutral20"
                      fontWeight={500}
                    >
                      {classText}
                    </Typography>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          )}
          {value !== undefined && (
            <Stack>
              <Typography
                variant="body3"
                color="mockup.neutral40"
                fontWeight={500}
                textAlign={bottomTextTitle && bottomText ? "end" : "start"}
              >
                {t("value")}
              </Typography>
              <Coin amount={`${value}`} />
            </Stack>
          )}
        </Stack>
      )}
    </ItemWrapper>
  )
}

export default BasicItem
