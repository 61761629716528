import React, { useState, useRef, useEffect } from "react"

import { Divider, Stack, Typography, useTheme } from "@mui/material"
import { useMutationEditClass } from "api/reactQuery/mutations/classes"
import { useQueryClasses } from "api/reactQuery/queries/classes"
import { ReactComponent as IconArrow } from "assets/common/svg/arrowBack.svg"
import { ReactComponent as IconEyeClosed } from "assets/common/svg/eye-closed.svg"
import { ReactComponent as IconEyeOpen } from "assets/common/svg/eye-open.svg"
import { ReactComponent as IconTrash } from "assets/common/svg/trash.svg"
import DynamicIcon from "components/common/icon/dynamicIcon"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { selectUser } from "store/auth/auth.selectors"
import { checkSubscription, getErrorMessageFromTab } from "utils/api"

const HiddenClass = () => {
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const [isOpenHiddenClass, setIsOpenHiddenClass] = useState(false)
  const [hoveredId, setHoveredId] = useState<number | null>(null)
  const theme = useTheme()
  const userInfo = useAppSelector(selectUser)
  const queryClient = useQueryClient()
  const modalRef = useRef<HTMLDivElement>(null)

  const { mutate, isLoading: isLoadingMutate } = useMutationEditClass({
    options: {
      onSuccess: (variables) => {
        queryClient.invalidateQueries(["classes"])

        showSnackbar({
          title: t("addNewClass.youHaveRestoredClassFromTheArchive", {
            class: variables.data.name,
          }),
          variant: "success",
        })
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const { data } = useQueryClasses({
    options: {
      enabled: checkSubscription(userInfo),
    },
  })

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpenHiddenClass &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsOpenHiddenClass(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpenHiddenClass])

  return (
    <>
      <Stack
        mt={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpenHiddenClass(!isOpenHiddenClass)}
        sx={{
          cursor: "pointer",
          ":hover": {
            transition: "opacity .2s",
            opacity: ".7",
          },
          position: "relative",
          "& .arrow-icon": {
            display: "none",
          },
          "&:hover .arrow-icon ": {
            display: "block",
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          p="12px"
          columnGap="8px"
          color={
            isOpenHiddenClass
              ? theme.palette.mockup.secondary80
              : theme.palette.common.white
          }
        >
          <IconTrash />
          <Typography
            color={
              isOpenHiddenClass
                ? theme.palette.mockup.secondary80
                : theme.palette.common.white
            }
            fontWeight="500"
          >
            Archived Classes
          </Typography>
        </Stack>

        <IconArrow
          className="arrow-icon"
          style={{
            color: isOpenHiddenClass
              ? theme.palette.mockup.secondary80
              : theme.palette.common.white,
            rotate: "180deg",
            marginRight: "15px",
          }}
        />
      </Stack>

      <Stack
        ref={modalRef}
        className="smooth-scrollbar"
        sx={{
          zIndex: isOpenHiddenClass ? 10 : -1,
          backgroundColor: theme.palette.mockup.primary60,
          position: "absolute",
          bottom: 0,
          left: isOpenHiddenClass ? "265px" : "0",
          width: "269px",
          px: "10px",
          maxHeight: "500px",
          opacity: isOpenHiddenClass ? 1 : 0,
          transition: `opacity .3s ease-in-out, left 0.1s ease-in-out ${
            !isOpenHiddenClass ? ".3s" : "0s"
          }`,
          overflowY: "auto",
          pointerEvents: isOpenHiddenClass ? "auto" : "none",
        }}
      >
        <Stack sx={{ p: 2 }}>
          {!data?.data.filter((item) => !item.is_class_visible).length && (
            <Typography
              fontWeight="500"
              color="common.white"
              sx={{ textAlign: "center" }}
            >
              There are no classes to display{" "}
            </Typography>
          )}
          {data?.data
            .filter((item) => !item.is_class_visible)
            .sort((a, b) =>
              a.name.localeCompare(b.name, undefined, {
                sensitivity: "base",
              })
            )
            .map((classObj) => (
              <Stack
                key={classObj.id}
                justifyContent="center"
                alignContent="space-between"
                color={theme.palette.common.white}
                onMouseEnter={() => setHoveredId(classObj.id)}
                onMouseLeave={() => setHoveredId(null)}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" width="90%">
                    <DynamicIcon name={classObj.icon} variant="outlined" />
                    <Typography
                      noWrap
                      sx={{
                        py: "4px",
                        width: "80%",
                        px: "10px",
                      }}
                      color="common.white"
                      fontWeight="500"
                    >
                      {classObj.name}
                    </Typography>
                  </Stack>
                  {hoveredId === classObj.id && !isLoadingMutate ? (
                    <IconEyeOpen
                      style={{
                        pointerEvents: isLoadingMutate ? "none" : "auto",
                        color: theme.palette.common.white,
                        minWidth: "20px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        mutate({
                          classId: classObj.id,
                          data: { is_class_visible: true },
                        })
                      }}
                    />
                  ) : (
                    <IconEyeClosed
                      style={{
                        color: theme.palette.common.white,
                        minWidth: "20px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Stack>
                <Divider
                  sx={{ width: "100%", my: 2, backgroundColor: "white" }}
                />
              </Stack>
            ))}
        </Stack>
      </Stack>
    </>
  )
}

export default HiddenClass
