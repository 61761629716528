import React from "react"

interface ToggleButtonProps {
  onClick: () => void
  isActive: boolean
  children: React.ReactNode
}

import { Typography } from "@mui/material"

export const ToggleButton = ({
  onClick,
  isActive,
  children,
}: ToggleButtonProps) => (
  <Typography
    onClick={onClick}
    color="common.white"
    p={1}
    flex="1"
    textAlign="center"
    sx={(theme) => ({
      borderRadius: "4px",
      ...(isActive && {
        backgroundColor: theme.palette.mockup.primary60,
      }),
    })}
  >
    {children}
  </Typography>
)
