import { configureStore } from "@reduxjs/toolkit"

import adminTransactionsReducer from "./adminTransaction/adminTransactions.slice"
import authReducer from "./auth/auth.slice"
import displayModeReducer from "./displayMode/displayMode.slice"
import menuReducer from "./menu/menu.slice"
import savingsReducer from "./savings/savings.slice"
import utilityReducer from "./utility/utility.slice"

export default configureStore({
  reducer: {
    menu: menuReducer,
    utility: utilityReducer,
    auth: authReducer,
    displayMode: displayModeReducer,
    savings: savingsReducer,
    adminTransactions: adminTransactionsReducer,
  },
})
