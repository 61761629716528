import React, { useState } from "react"

import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { ReactComponent as IconSearch } from "assets/common/svg/search.svg"
import { useTranslation } from "react-i18next"
import { FixedSizeList as List, ListChildComponentProps } from "react-window"

import { StudentProps } from "../SchoolTransactions.types"

interface AutocompleteFieldProps {
  selectedOptions: StudentProps[]
  handleSelected: (option: StudentProps) => void
  options: StudentProps[]
}

type VirtualizedListBoxProps = {
  options: StudentProps[]
  selectedOptions: StudentProps[]
  handleSelected: (option: StudentProps) => void
} & React.HTMLAttributes<HTMLDivElement>

/**
 * TODO(check) - renderowanie zaznaczonych userów, powoduje scrollowie do top listy
 */

const VirtualizedListBox = React.forwardRef<
  HTMLDivElement,
  VirtualizedListBoxProps
>(function VirtualizedListBox(
  {
    options,
    selectedOptions,
    handleSelected,
    ...other
  }: VirtualizedListBoxProps,
  ref
) {
  const handleOptionClick = (
    event: React.MouseEvent | React.ChangeEvent,
    option: StudentProps
  ): void => {
    event.preventDefault()
    handleSelected(option)
  }

  const renderRow = React.memo(({ index, style }: ListChildComponentProps) => {
    const option = options[index]
    const isSelected = selectedOptions.some((item) => item.id === option.id)

    return (
      <div key={option.id} style={style}>
        <li
          onClick={(event) => {
            event.preventDefault()
            handleOptionClick(event, option)
          }}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px",
            cursor: "pointer",
          }}
        >
          <Checkbox
            checked={isSelected}
            onChange={(event) => {
              event.preventDefault()
              handleOptionClick(event, option)
            }}
          />
          <Stack direction="row" columnGap={1}>
            <Typography fontWeight="500" color="mockup.neutral10">
              {option.first_name} {option.last_name}
            </Typography>
            <Typography fontWeight="500" color="mockup.neutral40">
              ({option.login})
            </Typography>
          </Stack>
        </li>
      </div>
    )
  })

  renderRow.displayName = "renderRow"

  return (
    <div ref={ref} {...other}>
      <List height={300} width="100%" itemSize={30} itemCount={options.length}>
        {renderRow}
      </List>
    </div>
  )
})

const AutocompleteField = ({
  selectedOptions,
  handleSelected,
  options,
}: AutocompleteFieldProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [filteredOptions, setFilteredOptions] = React.useState(options)
  const [isFocus, setIsFocus] = useState(false)

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    const lowercasedValue = value.trim().toLowerCase()
    setFilteredOptions(
      options.filter((option) =>
        `${option.first_name} ${option.last_name}`
          .toLowerCase()
          .includes(lowercasedValue)
      )
    )
  }

  React.useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  return (
    <Autocomplete<StudentProps, false>
      disableCloseOnSelect
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      isOptionEqualToValue={(option, value) => option.id === value.id} // Porównanie unikalnych wartości
      onInputChange={handleInputChange}
      ListboxComponent={React.forwardRef<
        HTMLDivElement,
        React.HTMLAttributes<HTMLDivElement>
      >(function VirtualizedListBoxComp(listboxprops, ref) {
        return (
          <VirtualizedListBox
            selectedOptions={selectedOptions}
            handleSelected={handleSelected}
            ref={ref}
            {...listboxprops}
            options={filteredOptions}
          />
        )
      })}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            variant="outlined"
            label={t("adminDashboard.searchForStudents")}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch
                    style={{ color: theme.palette.mockup.primary60 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiInputLabel-root": {
                transform: "translate(40px, 16px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
            }}
            InputLabelProps={{
              shrink: isFocus,
            }}
          />
        )
      }}
      options={filteredOptions}
    />
  )
}
export default AutocompleteField
