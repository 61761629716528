import React from "react"

import { ReactComponent as IconQuestion } from "@common_assets/svg/question.svg"
import { Box, Typography, useTheme } from "@mui/material"

const HelperOption = () => {
  const theme = useTheme()

  return (
    <>
      {process.env.REACT_APP_TYPE === "classequity" && (
        <a
          href="https://help.classbank.com/en/"
          target="_blank"
          rel="noreferrer"
        >
          <Box
            sx={{
              cursor: "pointer",
              ":hover": {
                transition: "opacity .2s",
                opacity: ".7",
              },
            }}
            padding="12px"
            columnGap="10px"
            display="flex"
            color={theme.palette.mockup.neutral100}
            width="100%"
            alignItems="center"
          >
            <IconQuestion />
            <Typography>Help center</Typography>
          </Box>
        </a>
      )}
    </>
  )
}
export default HelperOption
