import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  SchoolTransactionsArgs,
  SchoolTransactionsResponse,
  SchoolTransactionsType,
  TeacherAdminArgs,
  TeacherAdminDeleteBonusesTypes,
  TeacherAdminDeleteBonusesTypesArgs,
  TeacherAdminDeleteFinesTypes,
  TeacherAdminDeleteFinesTypesArgs,
  TeacherAdminPatchBonusesResponse,
  TeacherAdminPatchBonusesTypes,
  TeacherAdminPatchBonusesTypesArgs,
  TeacherAdminPatchFinesResponse,
  TeacherAdminPatchFinesTypes,
  TeacherAdminPatchFinesTypesArgs,
  TeacherAdminPostBonusesResponse,
  TeacherAdminPostBonusesTypes,
  TeacherAdminPostBonusesTypesArgs,
  TeacherAdminResponse,
  TeacherAdminTypes,
  TeacherManagementDelete,
  TeacherManagementDeleteArgs,
} from "./adminTeacher.types"

export const useMutationSchoolTransactions = (args: SchoolTransactionsArgs) =>
  useMutation<
    AxiosResponse<SchoolTransactionsResponse>,
    AxiosError,
    SchoolTransactionsType
  >(
    async (data) => {
      return await axiosInstance.delete(
        Endpoint.TEACHER_ADMIN_SCHOOL_TRANSACTIONS,
        { data }
      )
    },
    { ...args.options }
  )

export const useMutationTeacherAdmin = (args: TeacherAdminArgs) =>
  useMutation<
    AxiosResponse<TeacherAdminResponse>,
    AxiosError,
    TeacherAdminTypes
  >(
    async (data) =>
      axiosInstance.post(
        Endpoint.TEACHER_ADMIN_TEACHERS_MANAGEMENT_ID_SET_ADMIN.replace(
          "ID",
          `${data.teacher_id}`
        ),
        data,
        {
          params: { is_school_admin: data.is_school_admin },
        }
      ),
    {
      ...args.options,
    }
  )

export const useMutationTeacherManagementDelete = (
  args: TeacherManagementDeleteArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, TeacherManagementDelete>(
    async (data) =>
      axiosInstance.delete(
        `${Endpoint.TEACHER_ADMIN_TEACHERS_MANAGEMENT}/${data.teacher_id}`,
        { data }
      ),
    { ...args.options }
  )

export const useMutationPostTeacherAdminBonuses = (
  args: TeacherAdminPostBonusesTypesArgs
) =>
  useMutation<
    AxiosResponse<TeacherAdminPostBonusesResponse[]>,
    AxiosError,
    TeacherAdminPostBonusesTypes[]
  >(async (data) => axiosInstance.post(Endpoint.TEACHER_ADMIN_BONUSES, data), {
    ...args.options,
  })

export const useMutationDeleteTeacherAdminBonuses = (
  args: TeacherAdminDeleteBonusesTypesArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, TeacherAdminDeleteBonusesTypes>(
    async (data) =>
      axiosInstance.delete(
        `${Endpoint.TEACHER_ADMIN_BONUSES}/${data.bonus_id}`,
        { data }
      ),
    {
      ...args.options,
    }
  )

export const useMutationPatchTeacherAdminBonuses = (
  args: TeacherAdminPatchBonusesTypesArgs
) =>
  useMutation<
    AxiosResponse<TeacherAdminPatchBonusesResponse>,
    AxiosError,
    TeacherAdminPatchBonusesTypes
  >(
    async (data) =>
      axiosInstance.patch(
        `${Endpoint.TEACHER_ADMIN_BONUSES}/${data.bonus_id}`,
        data
      ),
    {
      ...args.options,
    }
  )

export const useMutationPostTeacherAdminFines = (
  args: TeacherAdminPostBonusesTypesArgs
) =>
  useMutation<
    AxiosResponse<TeacherAdminPostBonusesResponse[]>,
    AxiosError,
    TeacherAdminPostBonusesTypes[]
  >(async (data) => axiosInstance.post(Endpoint.TEACHER_ADMIN_FINES, data), {
    ...args.options,
  })

export const useMutationDeleteTeacherAdminFines = (
  args: TeacherAdminDeleteFinesTypesArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, TeacherAdminDeleteFinesTypes>(
    async (data) =>
      axiosInstance.delete(`${Endpoint.TEACHER_ADMIN_FINES}/${data.fine_id}`, {
        data,
      }),
    {
      ...args.options,
    }
  )

export const useMutationPatchTeacherAdminFines = (
  args: TeacherAdminPatchFinesTypesArgs
) =>
  useMutation<
    AxiosResponse<TeacherAdminPatchFinesResponse>,
    AxiosError,
    TeacherAdminPatchFinesTypes
  >(
    async (data) =>
      axiosInstance.patch(
        `${Endpoint.TEACHER_ADMIN_FINES}/${data.fine_id}`,
        data
      ),
    {
      ...args.options,
    }
  )
