import React, { useEffect } from "react"

import { Checkbox, Grid, Stack, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import {
  selectIsClickedReset,
  selectTransactionsType,
} from "store/adminTransaction/adminTransactions.selectors"
import { setTransactionsType } from "store/adminTransaction/adminTransactions.slice"
import { TypeIn } from "store/adminTransaction/adminTransactions.types"

import { transactionTypes } from "./TransactionsType.utility"

const TransactionsType = () => {
  const changeToUpper = (initial: TypeIn[]) =>
    initial.map((el) => el.charAt(0).toUpperCase() + el.slice(1)) as TypeIn[]

  const isShouldReset = useAppSelector(selectIsClickedReset)

  const initialState = useAppSelector(selectTransactionsType)
  const [itemCollection, setItemCollection] = React.useState<TypeIn[]>(
    changeToUpper(initialState)
  )

  useEffect(() => {
    if (isShouldReset) {
      setItemCollection([])
    }
  }, [isShouldReset])
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleSelected = (item: TypeIn) => {
    const isSelected = itemCollection.some((el) => el === item)

    if (isSelected) {
      setItemCollection((prev) => prev.filter((el) => el !== item))
    } else {
      setItemCollection((prev) => [...prev, item])
    }
  }

  useEffect(() => {
    const changeToLowerCase = itemCollection.map(
      (item) => item.toLowerCase() as TypeIn
    )
    dispatch(setTransactionsType(changeToLowerCase))
  }, [itemCollection])

  return (
    <Stack rowGap={2}>
      <Typography>{t("adminDashboard.typeOfTransaction")}</Typography>
      <Grid container spacing={2}>
        {transactionTypes.map((item) => (
          <Grid item key={item} desktop={4} display="flex" alignItems="center">
            <Checkbox
              checked={itemCollection.some((el) => el === item)}
              onClick={() => handleSelected(item as TypeIn)}
            />
            <Typography fontWeight="500">{item}</Typography>
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}
export default TransactionsType
