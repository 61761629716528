import { CSSProperties, FC, ReactNode } from "react"

import { CircularProgress, Fab, FabProps, Tooltip } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"

interface IProps extends Omit<FabProps, "onClick"> {
  isLoading?: boolean
  tooltipText?: string
  Icon: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  iconStyle?: CSSProperties
}

const FabIconButton: FC<IProps> = ({
  Icon,
  onClick,
  tooltipText,
  isLoading,
  iconStyle,
  ...fabProps
}) => {
  return (
    <Tooltip
      title={tooltipText || ""}
      componentsProps={{
        tooltip: {
          sx: {
            whiteSpace: "pre-line",
          },
        },
      }}
    >
      <Fab size="small" onClick={onClick} {...fabProps}>
        <IconWrapper style={iconStyle}>
          {isLoading ? <CircularProgress size={24} /> : Icon}
        </IconWrapper>
      </Fab>
    </Tooltip>
  )
}

export default FabIconButton
