import React, { useEffect } from "react"

import { Outlet } from "react-router"
import { useNavigate } from "react-router-dom"

import { useAppSelector } from "../../hooks/store"
import { selectUser } from "../../store/auth/auth.selectors"
import { isTeacher } from "../../utils/roleCheck"
import { RoutePath } from "../Route.types"

const AdminProtected = () => {
  const userInfo = useAppSelector(selectUser)
  const navigate = useNavigate()

  useEffect(() => {
    if (!userInfo) return

    if (isTeacher(userInfo.user) && !userInfo.user.is_school_admin) {
      navigate(RoutePath.HOMEPAGE, { replace: true })
    }
  }, [userInfo, navigate])

  if (!userInfo) return <div>Loading...</div>

  return <Outlet />
}
export default AdminProtected
