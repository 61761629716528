import { FC } from "react"

import { Typography } from "@mui/material"
import { useMutationDeleteClassesTransactionItems } from "api/reactQuery/mutations/classesTransactionItems"
import Dialog from "components/common/dialog/dialog"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useLocation } from "react-router"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { TransactionTypeShort } from "ts/types/Transaction"
import { getErrorMessage, getErrorMessageFromTab } from "utils/api"

import {
  useMutationDeleteTeacherAdminBonuses,
  useMutationDeleteTeacherAdminFines,
} from "../../../../../api/reactQuery/mutations/adminTeacher"
import { getSetupClassTranslation } from "../../SetupClassTeacher.utils"

interface IProps {
  item: ITransactionItem | null
  variant: TransactionTypeShort
  isOpen: boolean
  handleClose: (reason?: "closed" | "itemDeleted") => void
}

const DialogDeleteItem: FC<IProps> = ({
  item,
  variant,
  isOpen,
  handleClose,
}) => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)
  const location = useLocation()
  const isAdmin = location.pathname.includes("admin")

  const classId = useClassId()

  const { isLoading: isLoadingDelete, mutate: deleteTransactionItem } =
    useMutationDeleteClassesTransactionItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(`classes${variant}`)
          showSnackbar({
            title: t(tKey.itemHasBeenDeleted, { name: item?.title }),
          })
          handleClose("itemDeleted")
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  const { mutate: deleteBonuses } = useMutationDeleteTeacherAdminBonuses({
    options: {
      onError: (err) => {
        showSnackbar({
          variant: "error",
          title: getErrorMessageFromTab(err),
        })
      },

      onSuccess: () => {
        queryClient.invalidateQueries([`admin${variant}`])
        handleClose("itemDeleted")
        showSnackbar({
          title: "Preset has been removed successfully!",
        })
      },
    },
  })

  const { mutate: deleteFines } = useMutationDeleteTeacherAdminFines({
    options: {
      onError: (err) => {
        showSnackbar({
          variant: "error",
          title: getErrorMessageFromTab(err),
        })
      },

      onSuccess: () => {
        queryClient.invalidateQueries([`admin${variant}`])
        handleClose("itemDeleted")
        showSnackbar({
          title: "Preset has been removed successfully!",
        })
      },
    },
  })

  if (!item) return null

  return (
    <Dialog
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          deleteTransactionItem({
            classId: Number(classId),
            itemId: item.id,
            variant,
          })
        }
      }}
      open={isOpen}
      onClose={() => handleClose("closed")}
      titleText={t(tKey.areYouSureYouWantToDeleteItem, {
        name: item.title,
      })}
      actionAcceptText={t("delete")}
      isLoading={isLoadingDelete}
      onActionButtonClick={() => {
        if (isAdmin) {
          if (variant === "bonus") {
            deleteBonuses({
              bonus_id: item.id,
            })
          } else {
            deleteFines({
              fine_id: item.id,
            })
          }
          return
        }
        deleteTransactionItem({
          classId: Number(classId),
          itemId: item.id,
          variant,
        })
      }}
    >
      <Typography variant="subtitle2" fontWeight={500}>
        {t("setup.thisActionCannotBeUndone")}.
      </Typography>
    </Dialog>
  )
}

export default DialogDeleteItem
