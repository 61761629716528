import React from "react"

import { Stack, Typography, useTheme } from "@mui/material"
import { ReactComponent as IconBulb } from "assets/common/svg/bulb.svg"
import { useIsBreakpointUp } from "hooks/breakpoint"

import SetupClassTeacher from "../../../setupClassTeacher"

const Fines = () => {
  const theme = useTheme()
  const isTablet = useIsBreakpointUp("tablet")
  return (
    <Stack rowGap={4}>
      <Stack position="relative" mb={2}>
        <Stack
          sx={{
            position: "absolute",
            top: isTablet ? "-40px" : "-28px",
            width: isTablet ? "calc(100% + 48px)" : "calc(100% + 32px)",
            left: isTablet ? "-24px" : "-16px",
            border: `1px solid ${theme.palette.mockup.primary80}`,
            borderLeft: "none",
            borderRight: "none",
            backgroundColor: theme.palette.common.white,
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            columnGap={1}
            px={2}
          >
            <IconBulb
              color={theme.palette.mockup.primary60}
              style={{ flexShrink: 0 }}
            />
            <Typography
              color="mockup.neutral10"
              sx={{
                textAlign: "center",
                py: 1,
                fontWeight: "500",
              }}
            >
              Add transaction presets that will appear in all classes.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <SetupClassTeacher variant="fine" />
    </Stack>
  )
}
export default Fines
