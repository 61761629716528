import React, { ReactElement } from "react"

import { Stack, Typography, useTheme } from "@mui/material"
import { NavLink } from "react-router-dom"

interface NavItemProps {
  Icon?: ReactElement
  IconActive?: ReactElement
  to: string
  name: string
}

const NavItem = ({ name, to, Icon, IconActive }: NavItemProps) => {
  const theme = useTheme()

  return (
    <NavLink
      to={to}
      end
      style={({ isActive }) => ({
        textDecoration: "none",
        color: theme.palette.common.white,
        ...(isActive && { color: theme.palette.mockup.secondary80 }),
      })}
    >
      {({ isActive }) => (
        <Stack
          flexDirection="row"
          alignItems="center"
          columnGap={1}
          px={1}
          py={1.5}
        >
          {isActive ? IconActive : Icon}
          <Typography>{name}</Typography>
        </Stack>
      )}
    </NavLink>
  )
}
export default NavItem
