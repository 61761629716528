import React from "react"

import { Stack } from "@mui/material"

import Sender from "../sender"
import ClassSearch from "./classSearch"
import TeacherSearch from "./teacheSearch"
import Title from "./title"
import TransactionsType from "./transactionsType"

const ModalContentFilters = () => {
  return (
    <Stack p={3} rowGap={4}>
      <ClassSearch />
      <TeacherSearch />
      <TransactionsType />
      <Title />
      <Sender />
    </Stack>
  )
}
export default ModalContentFilters
