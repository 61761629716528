import { FC } from "react"

import { ReactComponent as IconX } from "@common_assets/svg/cross.svg"
import { AccordionDetails, Grid, Stack, Typography, Box } from "@mui/material"
import ErrorText from "components/common/error/errorText"
import NoElementsMessage from "components/common/listing/noElementsMessage"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { TransactionType } from "ts/types/Transaction"

import Tile from "../tile"
import TilesSkeleton from "../tilesSkeleton"

interface IProps {
  selectedItems: ITransactionItem[]
  noElementsMessage: string
  variant: TransactionType
  data: ITransactionItem[] | undefined
  isLoading: boolean
  isError: boolean
  studentId?: number
  onTileClick: (item: ITransactionItem) => void
  fieldName: string
}

const DetailsTransaction: FC<IProps> = ({
  children,
  selectedItems,
  noElementsMessage,
  variant,
  data,
  isLoading,
  studentId,
  isError,
  onTileClick,
  fieldName,
}) => {
  const { t } = useTranslation()

  const { setValue } = useFormContext()

  const removeThisSameValues = (item: ITransactionItem) => {
    if (selectedItems?.find((variant) => variant.id === item.id)) {
      setValue(fieldName, [...selectedItems.filter((v) => v.id !== item.id)])
    }
  }

  return (
    <AccordionDetails>
      <Stack gap="16px">
        <Grid container rowSpacing="16px" columnSpacing="12px">
          {!isLoading &&
            data
              ?.sort((a, b) => a.title.localeCompare(b.title))
              .map((tile) => (
                <Grid
                  key={tile.id}
                  item
                  position="relative"
                  mobile={6}
                  desktop={4}
                >
                  <Tile
                    onClick={() => onTileClick(tile)}
                    title={tile.title}
                    amount={tile.amount}
                    id={tile.id}
                    variant={variant}
                    watcher={selectedItems}
                    selected={
                      !!selectedItems.find((item) => item.id === tile.id)
                    }
                  />
                  {!!selectedItems.find((item) => item.id === tile.id) && (
                    <Box
                      onClick={() => {
                        removeThisSameValues(tile)
                      }}
                      sx={(theme) => ({
                        position: "absolute",
                        top: "15px",
                        right: 0,
                        cursor: "pointer",
                        ":hover": {
                          transition: "color 0.3s",
                          color: theme.palette.mockup.error50,
                        },
                      })}
                    >
                      <IconX />
                    </Box>
                  )}
                </Grid>
              ))}
        </Grid>
        {!isLoading && data?.length === 0 && (
          <NoElementsMessage title={noElementsMessage} />
        )}
        {isLoading && <TilesSkeleton />}
        {isError && <ErrorText />}
        {!data && (
          <Typography
            variant="subtitle1"
            textAlign="center"
            py="44px"
            color="mockup.darkBlue"
          >
            {studentId
              ? t(
                  "transaction.youDonyHavePermissionToTheListYouCanOnlySendTransactionsByCustomInput"
                )
              : t("transaction.toSeeTheListSelectStudentAndTheirClass")}
          </Typography>
        )}
        {children}
      </Stack>
    </AccordionDetails>
  )
}

export default DetailsTransaction
