import React, { useEffect, useState } from "react"

import {
  Checkbox,
  FormControlLabel,
  Stack,
  styled,
  Typography,
} from "@mui/material"
import { useMutationPostClassesStudents } from "api/reactQuery/mutations/classesStudents"
import { useQueryClassesCandidates } from "api/reactQuery/queries/classesCandidates"
import Dialog from "components/common/dialog/dialog"
import BasicTable from "components/common/table/basicTable"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useNavigate } from "react-router-dom"
import { FixedSizeList as List } from "react-window"
import { IStudentCandidate } from "ts/interfaces/Student"
import { getErrorMessageFromTab } from "utils/api"

interface AddAllStudentsProps {
  open: boolean
  onClose: () => void
}

interface RowProps {
  style: React.CSSProperties
  index: number
  isScrolling?: boolean
}

const PulseText = styled(Typography)({
  textAlign: "center",
  animation: "pulse 1.5s infinite",
  "@keyframes pulse": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 1,
    },
  },
})

const DialogAddAllStudents = ({ open, onClose }: AddAllStudentsProps) => {
  const [currentArray, setCurrentArray] = useState<IStudentCandidate[]>([])
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const classId = useClassId()
  const { showSnackbar } = useCustomSnackbar()
  const navigate = useNavigate()

  const { data, isLoading } = useQueryClassesCandidates({
    classId: Number(classId),
    isOpen: open,
    options: {
      enabled: open,
      cacheTime: 0,
    },
  })

  useEffect(() => {
    if (data) {
      setCurrentArray(data.data)
    }
  }, [data])

  const { mutate, isLoading: isLoadingPost } = useMutationPostClassesStudents({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: "All of students have been successfully added",
        })
        onClose()

        setInterval(() => {
          navigate(0)
        }, 1000)
      },
      onError: (err) => {
        showSnackbar({
          variant: "error",
          title: getErrorMessageFromTab(err),
        })
      },
    },
  })

  const handleSlected = () => {
    const all = data?.data.map((el) => el.id)

    if (selectedUsers.length === all?.length) {
      setSelectedUsers([])
    } else {
      if (all) setSelectedUsers(all)
    }
  }

  const handleSelectedSingle = (id: number) => {
    const matchUser = selectedUsers.find((el) => el === id)
    if (matchUser) {
      const filter = selectedUsers.filter((el) => el !== id)
      setSelectedUsers(filter)
    } else {
      setSelectedUsers((prev) => [...prev, id])
    }
  }

  const Row = ({ index, style, isScrolling }: RowProps) => {
    const person = currentArray[index]
    return (
      <Stack sx={{ ...style }} key={index}>
        {isScrolling ? (
          <Typography variant="body2" sx={{ opacity: 0.5, ml: "10px" }}>
            Loading
          </Typography>
        ) : (
          <FormControlLabel
            sx={{
              "&.MuiFormControlLabel-root": {
                ml: 0,
              },
            }}
            control={
              <Checkbox
                checked={selectedUsers.some((el) => el === person.id)}
                onChange={() => handleSelectedSingle(person.id)}
              />
            }
            label={
              <Typography color="mockup.neutral10">
                {person.first_name} {person.last_name}
              </Typography>
            }
          />
        )}
      </Stack>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setSelectedUsers([])
        setCurrentArray([])
      }}
      isLoading={isLoadingPost}
      onActionButtonClick={() => {
        mutate({
          classId: Number(classId),
          data: {
            existing_student_ids: selectedUsers,
            new_students: [],
          },
        })
      }}
      actionAcceptButtonProps={{
        disabled: isLoadingPost || !selectedUsers.length,
      }}
    >
      {isLoading ? (
        <Stack>
          <PulseText>Loading candidate list, please wait...</PulseText>
        </Stack>
      ) : (
        <>
          {!currentArray.length ? (
            <Stack>
              <Typography sx={{ textAlign: "center" }}>
                No candidates found
              </Typography>
            </Stack>
          ) : (
            <BasicTable
              wrapperProps={{ mt: 3 }}
              head={
                <BasicTable.Row>
                  <BasicTable.Cell>
                    <FormControlLabel
                      sx={{
                        "&.MuiFormControlLabel-root": {
                          ml: 0,
                        },
                      }}
                      control={
                        <Checkbox
                          checked={
                            !!selectedUsers.length &&
                            selectedUsers.length === data?.data.length
                          }
                          onChange={handleSlected}
                          indeterminate={
                            !!selectedUsers.length &&
                            selectedUsers.length !== data?.data.length
                          }
                        />
                      }
                      label={
                        <Typography variant="body3" color="mockup.primary60">
                          Name
                        </Typography>
                      }
                    />
                  </BasicTable.Cell>
                </BasicTable.Row>
              }
              body={
                <BasicTable.Row>
                  <BasicTable.Cell>
                    <List
                      useIsScrolling
                      height={400}
                      itemCount={currentArray.length}
                      itemSize={50}
                      width="100%"
                      className="smooth-scrollbar"
                    >
                      {Row}
                    </List>
                  </BasicTable.Cell>
                </BasicTable.Row>
              }
            />
          )}
        </>
      )}
    </Dialog>
  )
}
export default DialogAddAllStudents
