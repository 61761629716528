export const colors = {
  primary10: "#233186",
  primary20: "#3548BF",
  primary40: "#C2D6FF",
  primary60: "#4A5FDF",
  primary70: "#E2F4FE",
  primary80: "#8595F4",
  primary90: "#D2DAF9",
  primary95: "#EEF1FE",
  primary99: "#F9FAFF",
  primary_base: "#375DFB",
  primaryLight: "#162664",

  secondary60: "#F9AB11",
  secondary80: "#FEE440",
  secondary90: "#FDF4C0",

  tertiary20: "#C7586A",
  tertiary40: "#EC6F86",
  tertiary90: "#F5C7D0",

  additional20: "#178BCF",
  additional40: "#8DD0F7",
  additional90: "#D0EDFD",

  neutral0: "#0C0C0E",
  neutral10: "#444252",
  neutral10Opacity: "#4442524D",
  neutral20: "#595765",
  neutral30: "#6D6C79",
  neutral40: "#82818C",
  neutral50: "#292833",
  neutral50A: "#97969F",
  neutral60: "#8F99A3",
  neutral90: "#EAEAEC",
  neutral95: "#F5F5F5",
  neutral100: "#FFFFFF",
  neutralWeak100: "#F6F8FA",

  success30: "#1F280B",
  success50: "#27AE60",
  success60: "#81A72E",
  success90: "#D9EDB0",
  successLight: "#CBF5E5",

  error30: "#8C1D18",
  error50: "#DC362E",
  error90: "#F9DEDC",

  darkBlue: "#352B7A",
  navy: "#001A72",
  grey70: "#373D43",
  grey80: "#535C65",
  primary30: "#4053C2",
  lightBlue: "#DDE2FD",
  brightBlue: "#4A5FDF",
  neutral70: "#090A0B",
  dashboardBlue: "#F2F5FF",

  bankeePrimary10: "#476EB4",
  bankeePrimary20: "#8E90C6",

  bankeeSecondary80: "#FFDD6C",
  bankeeSecondary90: "#FAE0B1",

  bankeeTertiary20: "#E9586C",

  bankeeAdditional20: "#0586A7",
  bankeeAdditional40: "#A3D8E4",
  bankeeAdditional90: "#CBE7E6",

  bankeeError50: "#F18A6D",
}

export const colorsBankee = {
  primary10: "#2F4A7A",
  primary20: "#476EB4",
  primary30: "#3D62A6",
  primary60: "#476EB4",
  primary80: "#85ACF2",
  primary90: "#A8C9C8",
  primary95: "#CBE7E6",
  primary99: "#F2FCFC",

  secondary60: "#E0B831",
  secondary80: "#FFDD6C",
  secondary90: "#FAE0B1",

  tertiary20: "#CC5737",
  tertiary40: "#F18A6D",
  tertiary90: "#D9AB9E",

  additional20: "#62649C",
  additional40: "#8E90C6",
  additional90: "#BABCDE",

  neutral0: "#0C0C0E",
  neutral10: "#444252",
  neutral10Opacity: "#4442524D",
  neutral20: "#595765",
  neutral30: "#6D6C79",
  neutral40: "#82818C",
  neutral50: "#292833",
  neutral50A: "#97969F",
  neutral60: "#8F99A3",
  neutral90: "#EAEAEC",
  neutral95: "#F5F5F5",
  neutral100: "#FFFFFF",

  success30: "#1F280B",
  success50: "#27AE60",
  success60: "#81A72E",
  success90: "#D9EDB0",

  error30: "#8C1D18",
  error50: "#DC362E",
  error90: "#F9DEDC",

  darkBlue: "#352B7A",
  navy: "#001A72",
  grey70: "#373D43",
  grey80: "#535C65",
  lightBlue: "#DDE2FD",
  brightBlue: "#4A68dF",
  neutral70: "#090A0B",
  dashboardBlue: "#F2F5FF",
}
